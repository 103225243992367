import { useQuery } from 'react-query';
import { Redemption } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchCampaignRedemptions = (campaignId: string | number) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: redemptions, isLoading } = useQuery<Redemption[]>({
    queryKey: [
      'campaigns',
      typeof campaignId === 'string' ? campaignId : campaignId?.toString(),
      'redemptions',
    ],
    queryFn: () => campaignAPI.fetchCampaignRedemptions(campaignId!),
    enabled: Boolean(campaignId && campaignAPI.jwtToken),
  });

  return {
    redemptions: redemptions || [],
    isLoading,
  };
};

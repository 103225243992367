import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { ShopifyEmbed } from './ShopifyEmbed';

const theme = createTheme();

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
}));

// All shopify embed paths
const SHOPIFY_EMBED_PATHS: any = {
  manifoldgate: 'manifold-token-gate',
  'merch-bridge-dg': 'merch-bridge-dg',
  manifodl: 'manifold-dev-merch-bridge',
};

export const ShopifyComplete = () => {
  const { appName } = useParams<{ appName: string }>();
  const history = useHistory();
  const query = useQueryParams();
  const shop = query.get('shop');
  const isEmbed = window.top !== window.self;
  const shouldEmbed = !!SHOPIFY_EMBED_PATHS[appName];
  const [showError, setShowError] = useState(false);
  const [linking, setLinking] = useState(true);

  const completeAuthentication = React.useCallback(() => {
    const baseBackendURL = `https://6g5vuj5b15.execute-api.us-east-1.amazonaws.com/prod/campaign/shopify`;
    const urlToFetch = `${baseBackendURL}/${appName}/complete/embedded_app${
      window.location.search
    }&stage=${query.get('network') || 'mainnet'}`;

    const wrapper = async () => {
      try {
        const isLinkedResp = await fetch(
          `https://${
            process.env.REACT_APP_REDEEM_SERVER_HOST
          }/shopify/${appName}/check?shop=${encodeURIComponent(shop as string)}`
        );

        if (isLinkedResp.status !== 200) {
          const resp = await fetch(urlToFetch);
          if (resp.status !== 200) {
            throw new Error('App sync failed');
          }
        }

        setLinking(false);

        if (shouldEmbed && !isEmbed) {
          window.location.href = `https://${shop}/admin/apps/${SHOPIFY_EMBED_PATHS[appName]}`;
        } else if (!isEmbed) {
          history.push('/');
        } else {
          history.push(`/shopify/${appName}`);
        }
      } catch (error) {
        console.log(error);
        setShowError(true);
      }
    };
    wrapper();
    // @ts-ignore
  }, []);

  useEffect(() => {
    completeAuthentication();
  }, [completeAuthentication]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Main>
          <>
            {showError ? (
              <Box>Authentication error, please try again</Box>
            ) : (
              <>
                {linking ? (
                  <Box>Authenticating your shopify account...</Box>
                ) : (
                  <>
                    {!isEmbed ? (
                      <Box>Shopify authentication complete! Redirecting...</Box>
                    ) : (
                      <ShopifyEmbed />
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Main>
      </Box>
    </ThemeProvider>
  );
};

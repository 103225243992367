import { useQuery } from 'react-query';
import { ShopifyProduct } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchCampaignShopifyProducts = (campaignId?: number) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: shopifyProducts, isLoading } = useQuery<ShopifyProduct[]>({
    queryKey: ['campaigns', campaignId?.toString(), 'shopify_products'],
    queryFn: () => campaignAPI.fetchCampaignShopifyProducts(campaignId!),
    enabled: Boolean(campaignId && campaignAPI.jwtToken),
  });

  return {
    shopifyProducts: shopifyProducts || [],
    isLoading,
  };
};

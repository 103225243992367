// utility function for numeric operations

export function floatWithTwoDecimalsFromString(value: string) {
  const twoDecimals = value.match(/^-?\d+(?:\.\d{0,2})?/)?.[0];
  if (!twoDecimals) {
    return 0;
  }
  return parseFloat(twoDecimals);
}

export function displayWithTwoDecimals(value: string) {
  const [whole, entireDecimals] = value.split('.');
  let twoDecimals = entireDecimals?.substring(0, 2) ?? '';
  while (twoDecimals.length < 2) {
    twoDecimals += '0';
  }
  return whole + '.' + twoDecimals;
}

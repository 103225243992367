import { useQuery } from 'react-query';
import { Campaign } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchCampaign = (campaignId?: string | number) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: campaign, isLoading } = useQuery<Campaign>({
    queryKey: ['campaigns', typeof campaignId === 'string' ? campaignId : campaignId?.toString()],
    queryFn: () => campaignAPI.fetchCampaign(campaignId!),
    enabled: Boolean(campaignId && campaignAPI.jwtToken),
  });

  return {
    campaign,
    isLoading,
  };
};

import { Toast } from '@shopify/app-bridge-react';
import { createContext, useContext, useState } from 'react';

const ShopifyToastContext = createContext<{
  setToastMessage: (toastMessage: string, isError?: boolean) => void;
}>({
  // eslint-disable-next-line
  setToastMessage: () => {},
});

export const ShopifyToastProvider: React.FC = ({ children }) => {
  const [toast, setToast] = useState<{ message: string; isError: boolean }>({
    message: '',
    isError: false,
  });

  return (
    <ShopifyToastContext.Provider
      value={{
        setToastMessage: (toastMessage, isError = false) => {
          setToast({ message: toastMessage, isError });
        },
      }}
    >
      {children}
      {toast.message && (
        <Toast
          key={toast.message}
          content={toast.message}
          error={toast.isError}
          onDismiss={() => {
            setToast({ message: '', isError: false });
          }}
        />
      )}
    </ShopifyToastContext.Provider>
  );
};

export const useShopifyToastMessage = () => {
  return useContext(ShopifyToastContext);
};

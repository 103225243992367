import { Button, FormLayout, Popover, TextField, Tooltip } from '@shopify/polaris';
import { EditMinor } from '@shopify/polaris-icons';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCampaignAPI } from '../../../../hooks/useCampaignAPI';
import { useDeleteShopifyProductRuleMutation } from '../../../../hooks/useDeleteShopifyProductRuleMutation';
import {
  Campaign,
  CampaignShopifyRule,
  CampaignShopifyRuleRestrictionType,
  ShopifyProduct,
} from '../../../../utils/interfaces/campaign';
import { useShopifyToastMessage } from '../../hooks/useShopifyToastMessage';
import { ExclusiveCheckboxWithTooltip } from '../ExclusiveProductCheckboxWithTooltip';
import { ExplicitNoTotalCheckboxWithTooltip } from '../ExplicitNoTotalCheckboxWithTooltip';
import { EditProductFormValues } from './ProductTypes';

interface IProps {
  product: ShopifyProduct;
  campaign: Campaign;
  nonExclusiveRuleId: string;
}

export const EditProductPopoverButton = ({ campaign, product, nonExclusiveRuleId }: IProps) => {
  const [isEditProductOpen, setIsEditProductOpen] = useState(false);
  const { campaignAPI } = useCampaignAPI();
  const queryClient = useQueryClient();
  const deleteShopifyProductRuleMutation = useDeleteShopifyProductRuleMutation(
    campaign,
    product.productId,
    nonExclusiveRuleId
  );
  const [isMounted, setIsMounted] = useState(false);
  // if non-exclusive rule id does not exist, product is exclusive
  const exclusive = !nonExclusiveRuleId;
  const explicitNoTotal = product.total ? false : true;

  useEffect(() => {
    function changeMountedState(value: boolean) {
      setIsMounted(value);
    }

    changeMountedState(true);

    return function onUnmount() {
      changeMountedState(false);
    };
  }, []);

  const { setToastMessage } = useShopifyToastMessage();

  // TODO(DEVS-188): make this into a mutation
  const addNonExclusiveRule = async (): Promise<void> => {
    const campaignId = campaign.id;
    const rule: Partial<CampaignShopifyRule> = {
      restrictionType: CampaignShopifyRuleRestrictionType.UNRESTRICTED,
    };

    try {
      await campaignAPI.addCampaignRule(campaignId, product.productId, rule);
      // NOTE: queries are invalidated in the submit function
    } catch (e) {
      setToastMessage(
        `There was an error adding the product to the campaign as exclusive. Received "${
          (e as Error).message
        }"`,
        true
      );
    }
  };

  const formik = useFormik<EditProductFormValues>({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      // TODO: Remove once migration complete
      redemptionLimit: product.limit || 1,
      totalInventory: product.total || 0,
      exclusive,
      explicitNoTotal,
    },
    validate: (values) => {
      const errors: FormikErrors<EditProductFormValues> = {};

      if (
        (!values.redemptionLimit && values.redemptionLimit !== 0) ||
        Number.isNaN(values.redemptionLimit)
      ) {
        errors.redemptionLimit = 'Required';
      } else if (values.redemptionLimit < 1) {
        errors.redemptionLimit = 'Cannot be less than 1';
      }

      if (
        (!values.totalInventory && values.totalInventory !== 0) ||
        Number.isNaN(values.totalInventory)
      ) {
        errors.totalInventory = 'Required';
      } else if (values.totalInventory < 0) {
        errors.totalInventory = 'Cannot be less than 0';
      }

      if (!values.totalInventory && !values.explicitNoTotal) {
        errors.totalInventory =
          'Cannot set inventory to null without selecting Unlimited Inventory.';
      } else if (values.totalInventory && values.explicitNoTotal) {
        errors.totalInventory =
          'Cannot set a capped inventory while Unlimited Inventory is selected.';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const shouldUpdateProductDetails =
        values.redemptionLimit !== product.limit || values.totalInventory !== product.total;
      const shouldUpdateExclusive = values.exclusive !== exclusive;

      try {
        const editProductPromises = [];
        if (shouldUpdateProductDetails) {
          const updateObj = {
            productId: product.productId,
            limit: values.redemptionLimit,
            total: values.explicitNoTotal ? 0 : values.totalInventory,
          };
          editProductPromises.push(
            campaignAPI.updateShopifyProduct(campaign.id, product.productId, updateObj)
          );
        }
        if (shouldUpdateExclusive) {
          if (values.exclusive) {
            // unrestricted -> restricted: delete unrestricted rule
            editProductPromises.push(deleteShopifyProductRuleMutation.mutateAsync());
          } else {
            // restricted -> unrestricted: create unrestricted rule
            editProductPromises.push(addNonExclusiveRule());
          }
        }

        await Promise.all(editProductPromises);

        // invalidate queries
        const invalidateQueryPromises = [];
        if (shouldUpdateProductDetails) {
          invalidateQueryPromises.push(
            queryClient.invalidateQueries(['campaigns', campaign.id.toString(), 'shopify_products'])
          );
        }
        if (shouldUpdateExclusive && !values.exclusive) {
          // NOTE: delete mutation already handles invalidating the query
          invalidateQueryPromises.push(
            // TODO(DEVS-188): remove once we use the modify rules mutation
            queryClient.invalidateQueries(['campaigns', campaign.id.toString()])
          );
        }
        await Promise.all(invalidateQueryPromises);

        setIsEditProductOpen(false);
        setToastMessage('Updated product');
      } catch (e) {
        setToastMessage(
          `There was an error updating product. Received: ${(e as Error).message || e}`,
          true
        );
      }

      if (isMounted) {
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover
        preferredPosition="above"
        sectioned
        activator={
          <Tooltip content="Edit">
            <Button
              outline
              icon={EditMinor}
              onClick={() => {
                setIsEditProductOpen(true);
              }}
            ></Button>
          </Tooltip>
        }
        onClose={() => setIsEditProductOpen(false)}
        active={isEditProductOpen}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormLayout>
            <TextField
              autoComplete="off"
              label="Max Quantity Per Order"
              value={formik.values.redemptionLimit.toString()}
              onChange={(val) => formik.setFieldValue('redemptionLimit', parseInt(val))}
              error={formik.errors.redemptionLimit}
              type="number"
              requiredIndicator
              min={1}
            />
            <TextField
              autoComplete="off"
              label="Total inventory"
              value={formik.values.totalInventory.toString()}
              onChange={(val) => formik.setFieldValue('totalInventory', parseInt(val))}
              error={formik.errors.totalInventory}
              type="number"
              requiredIndicator
              min={0}
            />
            <ExclusiveCheckboxWithTooltip
              id={'exclusive'}
              checked={formik.values.exclusive}
              onChange={(val) => formik.setFieldValue('exclusive', !!val)}
              error={formik.errors.exclusive}
            />
            <ExplicitNoTotalCheckboxWithTooltip
              id={'explicitNoTotal'}
              checked={formik.values.explicitNoTotal}
              onChange={(val) => formik.setFieldValue('explicitNoTotal', !!val)}
              error={formik.errors.explicitNoTotal}
            />
            <Button
              submit
              primary
              // if invalid, disable. if untouched, disable.
              disabled={!formik.isValid || !formik.dirty}
              loading={formik.isSubmitting}
            >
              Submit
            </Button>
          </FormLayout>
        </form>
      </Popover>
    </div>
  );
};

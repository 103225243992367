import { useMutation, useQueryClient } from 'react-query';
import { useCampaignAPI } from './useCampaignAPI';

export const useDeleteCampaignMutation = () => {
  const queryClient = useQueryClient();
  const { campaignAPI } = useCampaignAPI();

  const mutation = useMutation<void, Error, { campaignId: number }>(
    async ({ campaignId }) => {
      return await campaignAPI.deleteCampaign(campaignId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaigns');
      },
    }
  );

  return mutation;
};

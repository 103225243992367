import { Card, DataTable, SkeletonBodyText } from '@shopify/polaris';
import { CSVLink } from 'react-csv';
import { useFetchCampaignRedemptions } from '../../../../hooks/useFetchCampaignRedemptions';
import { Campaign, Redemption } from '../../../../utils/interfaces/campaign';

export const ShopifyCampaignProgressCard: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  const { redemptions, isLoading } = useFetchCampaignRedemptions(campaign.id);
  const redemptionCount = `${campaign.redemptionCount}${
    campaign.maxRedemptions ? `/${campaign.maxRedemptions}` : ''
  }`;

  const downloadableRedemptions = redemptions.map((redemption: Redemption) => {
    return {
      ...redemption,
      submissions: JSON.stringify(redemption.submissions).replace(/['"]+/g, ''),
      nft: JSON.stringify(redemption.nft).replace(/['"]+/g, ''),
      orderId: redemption.submissions[0].orderId,
    };
  });

  return (
    <Card title="Campaign progress">
      <Card.Section>
        {isLoading ? (
          <SkeletonBodyText />
        ) : (
          <>
            <DataTable
              headings={['Network', 'Token ID', 'Contract address', 'Owner address', 'Order ID']}
              columnContentTypes={['text', 'text', 'text']}
              totals={['', '', redemptionCount]}
              totalsName={{
                singular: 'Total redemptions',
                plural: 'Total redemptions',
              }}
              showTotalsInFooter
              rows={redemptions.map((redemption) => {
                return [
                  redemption.network,
                  redemption.tokenId,
                  redemption.contractAddress,
                  redemption.ownerAddress,
                  redemption.submissions[0].orderId,
                ];
              })}
            ></DataTable>
            {downloadableRedemptions.length > 0 && (
              <CSVLink
                data={downloadableRedemptions}
                enclosingCharacter={`"`}
                filename={'campaign_redemptions_' + campaign.id.toString() + '.csv'}
              >
                Download as CSV
              </CSVLink>
            )}
          </>
        )}
      </Card.Section>
    </Card>
  );
};

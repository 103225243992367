import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

const poolData = {
  UserPoolId: 'us-east-1_iY2Ybzwb4', // Your user pool id here
  ClientId: '2uk4n0748vtnt73eoh0a1dsaoq', // Your client id here
};

const userPool = new CognitoUserPool(poolData);

export const CognitoContext = createContext<{
  cognitoSession?: CognitoUserSession;
  setCognitoSession: (cognitoSession: CognitoUserSession) => void;
  userPool: CognitoUserPool;
  preferredName: string;
  setPreferredName: (preferredName: string) => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCognitoSession: (_cognitoSession: CognitoUserSession) => {},
  userPool,
  preferredName: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPreferredName: (_preferredName: string) => {},
});

export const CognitoProvider: React.FC = ({ children }) => {
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | undefined>();
  const [preferredName, setPreferredName] = useState('');
  const useShopifyAuth = Boolean(useRouteMatch({ path: '/shopify' }));

  useEffect(() => {
    if (useShopifyAuth) {
      /**
       * Shopify auth has its own custom auth flow
       */
      return;
    }

    const cognitoUser = userPool.getCurrentUser();
    cognitoUser?.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (error) {
        alert(error.message || JSON.stringify(error));
        return;
      }

      if (session?.isValid()) {
        setCognitoSession(session);
        setPreferredName(session.getIdToken().payload.preferred_username);
      }
    });
  }, [useShopifyAuth]);

  return (
    <CognitoContext.Provider
      value={{
        cognitoSession,
        setCognitoSession,
        userPool,
        preferredName,
        setPreferredName,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};

export const useCognito = () => {
  return useContext(CognitoContext);
};

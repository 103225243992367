import {
  Card,
  Checkbox,
  ComplexAction,
  DescriptionList,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { QuestionMarkInverseMinor } from '@shopify/polaris-icons';
import { formatDistanceToNow, setSeconds } from 'date-fns';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCampaignAPI } from '../../../../hooks/useCampaignAPI';
import { Campaign } from '../../../../utils/interfaces/campaign';
import { useShopifyToastMessage } from '../../hooks/useShopifyToastMessage';
import { ShopifyCampaignStatusBadge } from '../ShopifyCampaignStatusBadge';
import { ShopifyDateTimePicker } from '../ShopifyDateTimePicker';

type EditFormValues = {
  startDate: number;
  endDate: number;
  explicitNoEndDate: boolean;
  allowUnlimitedRedemptions: boolean;
  redemptionsPerAddress: number | null;
  campaignName: string;
};

export const ShopifyCampaignHeaderCard: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { setToastMessage } = useShopifyToastMessage();

  const queryClient = useQueryClient();
  const { campaignAPI } = useCampaignAPI();

  const formik = useFormik<EditFormValues>({
    initialValues: {
      startDate: campaign.startDate!,
      endDate: campaign.endDate || Date.now(),
      explicitNoEndDate: !Boolean(campaign.endDate),
      allowUnlimitedRedemptions: campaign.redemptionsPerToken ? false : true,
      redemptionsPerAddress: campaign.redemptionsPerAddress ? campaign.redemptionsPerAddress : null,
      campaignName: campaign.name,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<EditFormValues> = {};

      if (!values.explicitNoEndDate) {
        if (!values.endDate) {
          errors.endDate = 'Please enter an end time';
        } else if (values.endDate && values.endDate <= values.startDate!) {
          errors.endDate = 'End Date cannot be before Start Date';
        }
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const updatedCampaign = await campaignAPI.updateCampaign({
          endDate: values.explicitNoEndDate ? null : values.endDate,
          startDate: values.startDate,
          campaignId: campaign.id,
          name: values.campaignName ? values.campaignName : campaign.name,
          redemptionsPerToken: values.allowUnlimitedRedemptions ? null : 1,
          redemptionsPerAddress: values.redemptionsPerAddress,
        });

        await queryClient.setQueryData<Campaign>(['campaigns', campaign.id], updatedCampaign);
        await queryClient.invalidateQueries('campaigns');

        /** Reset the `dirty` state so the saved button is disabled once again */
        resetForm({
          values,
        });
        setIsEditing(false);
        setToastMessage('Campaign updated');
      } catch (e) {
        setToastMessage(
          `There was an error updating your campaign. Received "${(e as Error).message}"`,
          true
        );
      }

      setSubmitting(false);
    },
  });

  // Note - can edit active campaigns within the rules defined by backend.
  // Example - you want to edit the end date of an active campaign
  const footerActions: ComplexAction[] = [
    { content: isEditing ? 'Cancel' : 'Edit', onAction: () => setIsEditing(!isEditing) },
  ];

  return (
    <Card
      secondaryFooterActions={footerActions}
      primaryFooterAction={
        isEditing
          ? {
              content: 'Save',
              onAction: () => {
                formik.submitForm();
              },
              loading: formik.isSubmitting,
              disabled: !formik.dirty,
            }
          : undefined
      }
    >
      <Card.Section>
        <DescriptionList
          items={[
            {
              term: 'Name',
              description: isEditing ? (
                <TextField
                  autoComplete="off"
                  label={'Gate Name'}
                  value={formik.values.campaignName}
                  onChange={(val) => formik.setFieldValue('campaignName', val)}
                  error={formik.errors.campaignName}
                />
              ) : (
                <span>{campaign.name}</span>
              ),
            },
            {
              term: 'Status',
              description: <ShopifyCampaignStatusBadge campaign={campaign} />,
            },
            {
              term: 'Start Date',
              description: isEditing ? (
                <ShopifyDateTimePicker
                  label=""
                  value={formik.values.startDate!}
                  onChange={(val) => formik.setFieldValue('startDate', val)}
                  error={formik.errors.startDate}
                />
              ) : (
                <Tooltip content={setSeconds(campaign.startDate!, 0).toTimeString()}>
                  <span>{formatDistanceToNow(campaign.startDate!, { addSuffix: true })}</span>
                </Tooltip>
              ),
            },
            {
              term: 'End Date',
              description: isEditing ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Checkbox
                    label="No End Date"
                    id="explicitNoEndDate"
                    checked={Boolean(formik.values.explicitNoEndDate)}
                    onChange={(val) => formik.setFieldValue('explicitNoEndDate', val)}
                    error={formik.errors.explicitNoEndDate}
                  />
                  {!formik.values.explicitNoEndDate && (
                    <div style={{ flex: '1', width: '100%' }}>
                      <ShopifyDateTimePicker
                        label=""
                        value={formik.values.endDate!}
                        onChange={(val) => formik.setFieldValue('endDate', val)}
                        error={formik.errors.endDate}
                      />
                    </div>
                  )}
                </div>
              ) : campaign.endDate ? (
                <Tooltip content={setSeconds(campaign.endDate!, 0).toTimeString()}>
                  <span>{formatDistanceToNow(campaign.endDate!, { addSuffix: true })}</span>
                </Tooltip>
              ) : (
                'No end date'
              ),
            },
            {
              term: (
                <Tooltip content="Allows only wallets holding tokens at the specified time to be eligible for the campaign. A snapshot date/time is required for campaigns using ERC-1155 tokens or if wallet-based redemption is desired.  Leaving this blank for ERC-721 tokens will allow campaign eligibility to travel with the NFT.">
                  <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    Snapshot
                    <QuestionMarkInverseMinor
                      style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                    />
                  </span>
                </Tooltip>
              ),
              description: campaign.snapshot ? 'Yes' : 'No',
            },
            {
              term: (
                <Tooltip content="Allow a token holder to redeem multiple times. If not selected, a token holder can only redeem once.">
                  <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    Allow Unlimited Redemptions
                    <QuestionMarkInverseMinor
                      style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                    />
                  </span>
                </Tooltip>
              ),
              description: isEditing ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Checkbox
                    label="Allow Unlimited Redemptions"
                    id="allowUnlimitedRedemptions"
                    checked={Boolean(formik.values.allowUnlimitedRedemptions)}
                    onChange={(val) => formik.setFieldValue('allowUnlimitedRedemptions', val)}
                    error={formik.errors.allowUnlimitedRedemptions}
                  />
                </div>
              ) : campaign.redemptionsPerToken ? (
                'No'
              ) : (
                'Yes'
              ),
            },
            {
              term: (
                <Tooltip content="Restrict number of redemptions per wallet (empty for unlimited).">
                  <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                    Max Redemptions Per Wallet
                    <QuestionMarkInverseMinor
                      style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                    />
                  </span>
                </Tooltip>
              ),
              description: isEditing ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <TextField
                    autoComplete="off"
                    label="Max redemptions for a wallet (empty for unlimited)"
                    value={
                      formik.values.redemptionsPerAddress
                        ? formik.values.redemptionsPerAddress.toString()
                        : ''
                    }
                    onChange={(val) =>
                      formik.setFieldValue('redemptionsPerAddress', val ? parseInt(val) : null)
                    }
                    error={formik.errors.redemptionsPerAddress}
                    type="number"
                    min={1}
                  />
                </div>
              ) : campaign.redemptionsPerAddress ? (
                campaign.redemptionsPerAddress
              ) : (
                'No Limit'
              ),
            },
          ]}
        />
      </Card.Section>
    </Card>
  );
};

import { Modal, TitleBar } from '@shopify/app-bridge-react';
import { Button, Card, IndexTable, Layout, Page, TextStyle, Tooltip } from '@shopify/polaris';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDeleteCampaignMutation } from '../../../hooks/useDeleteCampaignMutation';
import { useFetchAllCampaigns } from '../../../hooks/useFetchAllCampaigns';
import { Campaign } from '../../../utils/interfaces/campaign';
import { SkeletonShopifyCampaign } from '../components/campaign/SkeletonShopifyCampaign';
import { ShopifyCampaignStatusBadge } from '../components/ShopifyCampaignStatusBadge';
import { useShopifyState } from '../hooks/useShopifyState';
import { useShopifyToastMessage } from '../hooks/useShopifyToastMessage';

export const ShopifyCampaigns = () => {
  const history = useHistory();
  const { appName } = useParams<{ appName: string }>();
  const { shop } = useShopifyState();
  const [campaignToDelete, setCampaignToDelete] = useState<Campaign | null>(null);
  const { setToastMessage } = useShopifyToastMessage();
  const { campaigns, isLoading: isCampaignsLoading } = useFetchAllCampaigns(shop);
  const deleteMutation = useDeleteCampaignMutation();
  const closeCampaignDeletionModal = () => {
    setCampaignToDelete(null);
  };

  if (isCampaignsLoading) {
    return <SkeletonShopifyCampaign />;
  }

  return (
    <Page title="Your Product Gates">
      <TitleBar
        title="Product Gates"
        primaryAction={{ content: 'New Product Gate', url: `/shopify/${appName}/campaigns/new` }}
      />
      <Layout>
        <Layout.Section>
          <Card>
            <IndexTable
              resourceName={{ plural: 'campaigns', singular: 'campaign' }}
              itemCount={campaigns.length}
              selectable={false}
              headings={[
                { title: 'Name' },
                { title: 'Products' },
                { title: 'Start date' },
                { title: 'Status' },
                { title: 'Redemption count' },
                { title: 'Actions', hidden: true },
              ]}
              sort
            >
              {campaigns.map((campaign, index) => {
                return (
                  <IndexTable.Row
                    onNavigation={() => {
                      history.push(`/shopify/${appName}/campaigns/${campaign.id}`);
                    }}
                    id={campaign.id.toString()}
                    key={campaign.id}
                    position={index}
                  >
                    <IndexTable.Cell>
                      <div data-primary-link>
                        <TextStyle variation="strong">{campaign.name}</TextStyle>
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div data-primary-link>
                        <TextStyle>{campaign.products.map((x) => x.title).join(', ')}</TextStyle>
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Tooltip
                        content={campaign.startDate ? new Date(campaign.startDate).toString() : ''}
                      >
                        <div data-primary-link>
                          {campaign.startDate
                            ? formatDistanceToNow(new Date(campaign.startDate), { addSuffix: true })
                            : ''}
                        </div>
                      </Tooltip>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div data-primary-link>
                        <ShopifyCampaignStatusBadge campaign={campaign} />
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div data-primary-link>{campaign.redemptionCount}</div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div
                        style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Button plain primary url={`/shopify/${appName}/campaigns/${campaign.id}`}>
                          View
                        </Button>
                        <Button
                          plain
                          destructive
                          onClick={() => {
                            setCampaignToDelete(campaign);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell></IndexTable.Cell>
                  </IndexTable.Row>
                );
              })}
            </IndexTable>
          </Card>
        </Layout.Section>
      </Layout>
      <Modal
        title="Confirm Deletion"
        message={
          campaignToDelete
            ? `Are you sure you want to delete the campaign "${campaignToDelete!.name}" (id: ${
                campaignToDelete!.id
              })`
            : ''
        }
        open={Boolean(campaignToDelete)}
        primaryAction={{
          destructive: true,
          content: 'Delete Campaign',
          onAction: () =>
            deleteMutation.mutate(
              { campaignId: campaignToDelete!.id },
              {
                onSuccess: () => {
                  closeCampaignDeletionModal();
                  setToastMessage('Campaign deleted');
                },
                onError: (e) => {
                  // Can't delete in progress campaign
                  if (e.toString().includes('in progress')) {
                    setToastMessage(
                      `Cannot delete in-progress campaign. Please edit the end date before deleting.`,
                      true
                    );
                  } else {
                    setToastMessage(
                      `There was an error deleting the campaign. Received: "${e}"`,
                      true
                    );
                  }
                },
              }
            ),
          loading: deleteMutation.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: closeCampaignDeletionModal,
            disabled: deleteMutation.isLoading,
          },
        ]}
        onClose={closeCampaignDeletionModal}
      />
    </Page>
  );
};

import { useMutation } from 'react-query';
import { useCampaignAPI } from './useCampaignAPI';

type RefreshTokenMetadataParams = {
  network: number;
  tokenAddress: string;
};

export const useRefreshTokenMetadataMutation = () => {
  const { campaignAPI } = useCampaignAPI();

  const mutation = useMutation(async ({ network, tokenAddress }: RefreshTokenMetadataParams) => {
    return await campaignAPI.refreshTokenMetadata(network, tokenAddress);
  });

  return mutation;
};

import { useMutation, useQueryClient } from 'react-query';
import { Campaign } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useDeleteShopifyProductRuleMutation = (
  campaign: Campaign,
  productId: number,
  ruleId: string
) => {
  const queryClient = useQueryClient();
  const { campaignAPI } = useCampaignAPI();

  const mutation = useMutation<void, Error>(
    async () => {
      if (ruleId) {
        await campaignAPI.deleteCampaignRule(campaign.id!, productId, ruleId);
      }
    },
    {
      onSuccess: async () => {
        if (ruleId) {
          // TODO(DEVS-188): make sure the key is correct
          await queryClient.invalidateQueries(['campaigns', campaign.id.toString()]);
        }
      },
    }
  );

  return mutation;
};

import { IndexTable, Thumbnail } from '@shopify/polaris';
import { Campaign, DiscountType } from '../../../../utils/interfaces/campaign';
import { IRuleRowData } from '../../../../utils/interfaces/shopify';
import { displayWithTwoDecimals } from '../../../../utils/numUtils';
import { DeleteRuleButton } from './DeleteRuleButton';

interface IProps {
  rule: IRuleRowData;
  index: number;
  campaign: Campaign;
}

export const ShopifyCampaignRuleRow = ({ rule, index, campaign }: IProps): JSX.Element => {
  let sanitizedDiscountValue = rule.discountValue?.toString();
  if (rule.restrictionLabel === 'none') {
    sanitizedDiscountValue = 'N/A';
  } else if (rule.discountType === DiscountType.Percent) {
    sanitizedDiscountValue += ' %';
  } else {
    // amount
    sanitizedDiscountValue = displayWithTwoDecimals(sanitizedDiscountValue) + ' $';
  }

  return (
    <IndexTable.Row id={rule.id} position={index}>
      <IndexTable.Cell>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Thumbnail
            size="small"
            source={rule.product.productData.image?.src ?? ''}
            alt={`Image for product ${rule.product.productData.title}`}
          />
          {rule.product.productData.title}
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>{rule.network}</IndexTable.Cell>
      <IndexTable.Cell>{rule.contract}</IndexTable.Cell>
      <IndexTable.Cell>{rule.restrictionLabel}</IndexTable.Cell>
      <IndexTable.Cell>{rule.restrictionCriteria}</IndexTable.Cell>
      <IndexTable.Cell>{sanitizedDiscountValue}</IndexTable.Cell>
      <IndexTable.Cell>
        <DeleteRuleButton campaign={campaign} productId={rule.product.productId} ruleId={rule.id} />
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};

import { TitleBar } from '@shopify/app-bridge-react';
import {
  Banner,
  Button,
  Card,
  Collapsible,
  Combobox,
  ExceptionList,
  Heading,
  Icon,
  Layout,
  Listbox,
  Page,
  RadioButton,
  Select,
  Spinner,
  Stack,
  Tag,
  TextContainer,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import {
  AlertMinor,
  ChevronLeftMinor,
  ProductsMinor,
  RecentSearchesMajor,
  RiskMinor,
  SearchMinor,
} from '@shopify/polaris-icons';
import { FormikErrors, useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getStorefrontsForNetwork } from '@manifoldxyz/shared-storefront-contracts';
import { useCampaignAPI } from '../../../hooks/useCampaignAPI';
import { useFetchCampaign } from '../../../hooks/useFetchCampaign';
import { useFetchCampaignShopifyProducts } from '../../../hooks/useFetchCampaignShopifyProducts';
import { useFetchCampaignTokenNFTAttributes } from '../../../hooks/useFetchCampaignTokenNFTAttributes';
import { useRefreshTokenMetadataMutation } from '../../../hooks/useRefreshTokenMetadataMutation';
import {
  CampaignShopifyRule,
  CampaignShopifyRuleRestrictionType,
  DiscountType,
  TokenFilterType,
} from '../../../utils/interfaces/campaign';
import { SkeletonShopifyCampaign } from '../components/campaign/SkeletonShopifyCampaign';
import { useShopifyToastMessage } from '../hooks/useShopifyToastMessage';

type ManageRulesFormValues = {
  filterType: TokenFilterType;
  network: string; // Needs to be a string because selector dropdown requires string value
  contractAddress: string;
  tokenId: string;
  minTokenId: string;
  maxTokenId: string;
  searchAttributesInput: string;
  selectedProductId: string;
  selectedNftAttributes: Set<string>;
  discountValue: number;
  discountType: DiscountType;
};

export const ShopifyCampaignRuleManager = (): JSX.Element => {
  const { campaignId, appName } = useParams<{ campaignId: string; appName: string }>();
  const { campaign, isLoading } = useFetchCampaign(campaignId);
  const { campaignAPI } = useCampaignAPI();
  const { shopifyProducts, isLoading: isShopifyProductsLoading } = useFetchCampaignShopifyProducts(
    parseInt(campaignId)
  );
  const { setToastMessage } = useShopifyToastMessage();
  const queryClient = useQueryClient();
  const history = useHistory();

  const networks = [
    {
      name: 'ethereum',
      value: '1',
    },
    {
      name: 'sepolia',
      value: '11155111',
    },
    {
      name: 'polygon',
      value: '137',
    },
    {
      name: 'optimism',
      value: '10',
    },
    {
      name: 'base',
      value: '8453',
    },
  ];

  const changeDiscountType = (discount: DiscountType) => {
    formik.setFieldValue('discountType', discount);
    formik.setFieldTouched('discountType');
  };

  const formik = useFormik<ManageRulesFormValues>({
    initialValues: {
      filterType: 'no_filter',
      network: '1',
      contractAddress: '',
      tokenId: '',
      minTokenId: '',
      maxTokenId: '',
      searchAttributesInput: '',
      selectedNftAttributes: new Set<string>(),
      selectedProductId: '',
      discountValue: 10,
      discountType: DiscountType.Percent,
    },
    validateOnBlur: false,
    validate: (values) => {
      const errors: FormikErrors<Partial<ManageRulesFormValues>> = {};
      const selectedProductId =
        shopifyProducts.length === 1 ? shopifyProducts[0].productId : values.selectedProductId;
      if (!selectedProductId) {
        errors.selectedProductId = 'Product is required';
      }

      // discount validation
      if (
        (!values.discountValue && values.discountValue !== 0) ||
        Number.isNaN(values.discountValue)
      ) {
        errors.discountValue = 'Required';
      } else {
        switch (values.discountType) {
          case DiscountType.Percent:
            if (values.discountValue > 100 || values.discountValue < 0) {
              errors.discountValue = 'Must be between 0 and 100';
            } else if (values.discountValue.toString().includes('.')) {
              errors.discountValue = 'Must be an integer value';
            }
            break;
          case DiscountType.Amount:
            if (values.discountValue < 0) {
              errors.discountValue = 'Must be greater or equal to 0';
            }
            break;
        }
      }

      if (!values.contractAddress) {
        errors.contractAddress = 'Contract address is required';
      }
      // if current value is a shared contract AND filter type is one of none/attributes
      if (
        isSharedContract(parseInt(values.network), values.contractAddress) &&
        (values.filterType === 'no_filter' ||
          values.filterType === 'id_range' ||
          values.filterType === 'attributes')
      ) {
        errors.filterType = 'Invalid filter type for shared storefront contract';
      }

      switch (values.filterType) {
        case 'id_range':
          if (!values.minTokenId) {
            errors.minTokenId = 'Minimum Token ID required';
          }

          if (!values.maxTokenId) {
            errors.maxTokenId = 'Maximum Token ID required';
          }
          break;
        case 'attributes':
          if (values.selectedNftAttributes.size <= 0) {
            errors.searchAttributesInput = 'Requires at least 1 attribute';
          }
          break;
        case 'id':
          if (!values.tokenId) {
            errors.tokenId = 'Token ID is required';
          }
          // rely on backend for verification
          break;
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const selectedProductId =
        shopifyProducts.length === 1
          ? shopifyProducts[0].productId
          : formik.values.selectedProductId;
      let rule: Partial<CampaignShopifyRule> = {};
      // Need to parseInt because for some reason the network is
      // converted to a string above, probably when we toString()
      // it for display
      const network = parseInt(values.network);
      switch (values.filterType) {
        case 'no_filter':
          rule = {
            restrictionType: CampaignShopifyRuleRestrictionType.TOKEN,
            restriction: {
              network,
              tokenAddress: values.contractAddress,
            },
          };
          break;
        case 'id_range':
          rule = {
            restrictionType: CampaignShopifyRuleRestrictionType.TOKEN_RANGE,
            restriction: {
              network,
              tokenAddress: values.contractAddress,
              minTokenId: values.minTokenId,
              maxTokenId: values.maxTokenId,
            },
          };

          break;
        case 'id':
          rule = {
            restrictionType: CampaignShopifyRuleRestrictionType.INDIVIDUAL_TOKEN,
            restriction: {
              network,
              tokenAddress: values.contractAddress,
              tokenId: values.tokenId,
            },
          };

          break;
        case 'attributes':
          rule = {
            restrictionType: CampaignShopifyRuleRestrictionType.TOKEN_ATTRIBUTE,
            restriction: {
              network,
              tokenAddress: values.contractAddress,
              attributeEntries: nftAttributes.filter(({ traitType, value }) =>
                values.selectedNftAttributes.has(`${traitType}_${value}`)
              ),
            },
          };
      }

      if (rule.restriction) {
        // parse discount value only if there is a restriction
        const { discountValue, discountType } = formik.values;
        if (discountType === DiscountType.Amount) {
          // TODO: deal with more than 2 decimal values
          rule.discountAmount = discountValue;
          rule.discountPercent = 0;
        } else if (discountType === DiscountType.Percent) {
          // only accept ints
          rule.discountPercent = parseInt(discountValue.toString());
        }
      }

      if (rule.restriction) {
        rule!.restriction.tokenAddress = rule!.restriction?.tokenAddress.toLowerCase();
      }

      try {
        const res = await campaignAPI.addCampaignRule(campaign!.id, selectedProductId, rule!);
        console.log('NewCampaignRuleResponse: ', res);
        await queryClient.invalidateQueries(['campaigns', campaign!.id.toString(), 'rules']);
        setToastMessage('Added new rule');
        history.push(`/shopify/${appName}/campaigns/${campaignId}`);
      } catch (e) {
        setToastMessage(
          `There was an error creating this campaign rule. Received "${(e as Error).message}"`,
          true
        );
      }

      setSubmitting(false);
    },
  });

  const { nftAttributes, isLoading: isNFTAttributesLoading } = useFetchCampaignTokenNFTAttributes(
    parseInt(formik.values.network),
    formik.values.contractAddress
  );
  const refreshTokenMetadataMutation = useRefreshTokenMetadataMutation();

  // for discount types
  const [discountMoreInfoOpen, setDiscountMoreInfoOpen] = useState(false);

  const isSharedContract = useCallback(
    (networkId: number, contractAddress: string): string | '' => {
      const lowerCaseAddress = contractAddress.toLowerCase();
      const sharedStorefronts = getStorefrontsForNetwork(networkId);
      if (lowerCaseAddress in sharedStorefronts.map((x) => x.address)) {
        return sharedStorefronts.find((x) => x.address === lowerCaseAddress)!.name;
      }
      return '';
    },
    []
  );

  const handleToggleDiscountMoreInfoOpen = useCallback(
    () => setDiscountMoreInfoOpen((open) => !open),
    []
  );

  if (isLoading || !campaign) {
    return <SkeletonShopifyCampaign />;
  }

  // helper render functions
  /** Returns the controlled radio buttons for percentage/$ amount (discount type selector) */
  function renderDiscountType(): JSX.Element {
    return (
      <Stack alignment="center">
        <span>&nbsp;</span>
        <RadioButton
          label="%"
          id="percent"
          name="discountType"
          onChange={() => {
            changeDiscountType(DiscountType.Percent);
          }}
          checked={formik.values.discountType === DiscountType.Percent}
        />
        <RadioButton
          label="$"
          id="amount"
          name="discountType"
          onChange={() => {
            changeDiscountType(DiscountType.Amount);
          }}
          checked={formik.values.discountType === DiscountType.Amount}
        />
      </Stack>
    );
  }

  /** returns the discount value input box and the discount type selector */
  function renderDiscountInput(): JSX.Element {
    return (
      <Stack vertical alignment="leading">
        <TextField
          autoComplete="off"
          label="Discount Value"
          value={formik.values.discountValue.toString(10)}
          onChange={(val) => {
            formik.setFieldTouched('discountValue', true);
            // TODO: parse to 2 decimal points depending on discount type
            formik.setFieldValue('discountValue', parseFloat(val));
          }}
          error={formik.errors.discountValue}
          type="number"
          requiredIndicator
          min={0}
          connectedRight={renderDiscountType()}
        />
        {formik.values.discountValue === 0 && formik.touched.discountValue && (
          <ExceptionList
            items={[
              {
                icon: RiskMinor,
                status: 'critical',
                description: `WARNING: For a $0/0% discount product please make sure your store-level inventory
              is set to 0, otherwise anyone could utilize the product ID to construct
              a purchase URL and create an order. Also deselect "Track quantity"
              for item inventory settings or product will display as SOLD OUT.
              Inventory tracking/limiting for NFT-exclusive products can be done in the
              "Products" section of your campaign if desired.`,
              },
            ]}
          />
        )}
        <Button
          onClick={handleToggleDiscountMoreInfoOpen}
          ariaExpanded={discountMoreInfoOpen}
          ariaControls="discount-more-info-collapsible"
          plain
          disclosure={discountMoreInfoOpen ? 'up' : 'down'}
        >
          {discountMoreInfoOpen ? 'Show less' : 'Show more'}
        </Button>
        <Collapsible
          open={discountMoreInfoOpen}
          id="discount-more-info-collapsible"
          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
          expandOnPrint
        >
          <TextStyle variation="subdued">
            <TextContainer spacing="tight">
              <p>
                A discount can be formatted in percent or in amount. If your item is available to
                holders, we recommend setting the product's original price to a high price point
                (e.g.: $10,000) and offering a discount to your desired price.
              </p>
              <p>
                This ensures the integrity of your gate in the off chance a hacker injects your
                product into their cart &mdash; they would have to pay you the original price to
                successfully create an order!
              </p>
            </TextContainer>
          </TextStyle>
        </Collapsible>
      </Stack>
    );
  }

  function renderSharedContractWarning(): JSX.Element | null {
    const sharedStorefrontName = isSharedContract(
      parseInt(formik.values.network),
      formik.values.contractAddress
    );
    if (!sharedStorefrontName) {
      return null;
    }
    return (
      <ExceptionList
        items={[
          {
            icon: AlertMinor,
            status: 'warning',
            description: `Rule with a shared storefront contract (current: ${sharedStorefrontName}) must be limited by token id (individual or range)`,
          },
        ]}
      />
    );
  }

  function renderFilterTypeDetailSelectors(): JSX.Element | null {
    switch (formik.values.filterType) {
      case 'id':
        return (
          <TextField
            autoComplete="off"
            label="Token ID"
            name="tokenId"
            value={formik.values.tokenId}
            onChange={(val) => {
              formik.setFieldValue('tokenId', val.trim());
            }}
            error={formik.errors.tokenId}
            requiredIndicator
          />
        );
      case 'id_range':
        return (
          <Stack vertical>
            <TextField
              autoComplete="off"
              label="Min Token ID"
              name="minTokenId"
              value={formik.values.minTokenId}
              onChange={(val) => {
                formik.setFieldValue('minTokenId', val);
              }}
              error={formik.errors.minTokenId}
              requiredIndicator
              type="number"
            />
            <TextField
              autoComplete="off"
              label="Max Token ID"
              name="maxTokenId"
              value={formik.values.maxTokenId}
              onChange={(val) => {
                formik.setFieldValue('maxTokenId', val);
              }}
              error={formik.errors.maxTokenId}
              requiredIndicator
              type="number"
            />
          </Stack>
        );
      case 'attributes':
        if (isSharedContract(parseInt(formik.values.network), formik.values.contractAddress)) {
          return null;
        }
        const toggleAttribute = (attributeId: string) => {
          const newSelections = new Set(formik.values.selectedNftAttributes);

          if (formik.values.selectedNftAttributes.has(attributeId)) {
            newSelections.delete(attributeId);
          } else {
            newSelections.add(attributeId);
          }

          formik.setFieldValue('selectedNftAttributes', newSelections);
        };
        const filterRegex = new RegExp(formik.values.searchAttributesInput, 'i');
        const removeTag = ({ traitType, value }: { traitType: string; value: string }) => {
          toggleAttribute(`${traitType}_${value}`);
        };

        return (
          <Stack vertical>
            <Combobox
              preferredPosition="above"
              allowMultiple
              activator={
                <Combobox.TextField
                  autoComplete="off"
                  prefix={
                    isNFTAttributesLoading ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner size="small" />
                      </div>
                    ) : (
                      <Icon source={nftAttributes.length ? SearchMinor : AlertMinor} />
                    )
                  }
                  onChange={(val) => {
                    formik.setFieldValue('searchAttributesInput', val);
                  }}
                  label="Search attributes"
                  labelHidden
                  value={formik.values.searchAttributesInput}
                  placeholder="Search attributes"
                  disabled={!formik.values.contractAddress}
                  error={formik.values.contractAddress && formik.errors.searchAttributesInput}
                />
              }
            >
              {Boolean(formik.values.contractAddress) ? (
                <Listbox onSelect={(attributeId) => toggleAttribute(attributeId)}>
                  {isNFTAttributesLoading ? (
                    <Listbox.Loading accessibilityLabel="NFT Attributes Loading" />
                  ) : !nftAttributes.length ? (
                    <div
                      style={{
                        padding: '1rem',
                        display: 'flex',
                        flexFlow: 'column',
                        gap: '1rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon source={RecentSearchesMajor} color="base" />
                      <p color="var(--p-text)">No attributes found</p>
                    </div>
                  ) : (
                    nftAttributes
                      .filter(({ traitType, value }) => {
                        if (!formik.values.searchAttributesInput) {
                          return true;
                        }

                        const label = `${traitType}: ${value}`;
                        return label.match(filterRegex);
                      })
                      .map(({ traitType, value }) => {
                        const id = `${traitType}_${value}`;
                        const label = `${traitType}: ${value}`;
                        return (
                          <Listbox.Option
                            key={id}
                            value={id}
                            accessibilityLabel={label}
                            selected={formik.values.selectedNftAttributes.has(id)}
                          >
                            {label}
                          </Listbox.Option>
                        );
                      })
                  )}
                </Listbox>
              ) : null}
            </Combobox>
            {!formik.values.contractAddress && (
              <ExceptionList
                items={[
                  {
                    icon: RiskMinor,
                    status: 'warning',
                    description: `Enter contract address first.`,
                  },
                ]}
              />
            )}
            <TextContainer>
              <Stack>
                {nftAttributes
                  .filter((attr) =>
                    formik.values.selectedNftAttributes.has(`${attr.traitType}_${attr.value}`)
                  )
                  .map((attr) => {
                    const label = `${attr.traitType}: ${attr.value}`;

                    return (
                      <Tag key={label} onRemove={() => removeTag(attr)}>
                        {label}
                      </Tag>
                    );
                  })}
              </Stack>
            </TextContainer>
            {Boolean(formik.values.contractAddress) && (
              <Banner
                title="Not seeing attributes you expect?"
                status="info"
                action={{
                  content: 'Refresh metadata',
                  loading: refreshTokenMetadataMutation.isLoading,
                  onAction: async () => {
                    try {
                      await refreshTokenMetadataMutation.mutateAsync({
                        network: parseInt(formik.values.network),
                        tokenAddress: formik.values.contractAddress,
                      });
                      setToastMessage('Triggered refresh. Check again in a few minutes.');
                    } catch (e) {
                      setToastMessage(`Unable to refresh metadata. Received: "${e}"`, true);
                    }
                  },
                }}
              >
                <p>Click here to refresh token attributes (may take a while)</p>
              </Banner>
            )}
          </Stack>
        );
    }
    return null;
  }

  return (
    <>
      <Button icon={ChevronLeftMinor} url={`/shopify/${appName}/campaigns/${campaignId}`}></Button>

      <Page title={`${campaign.name} - Manage Rules`}>
        <TitleBar
          title="rules"
          breadcrumbs={[
            {
              content: 'Product Gates',
              url: `/shopify/${appName}/campaigns`,
            },
            {
              content: campaignId,
              url: `/shopify/${appName}/campaigns/${campaignId}`,
            },
          ]}
          primaryAction={{ content: 'New Product Gate', url: `/shopify/${appName}/campaigns/new` }}
        />
        <Layout>
          <Layout.Section>
            <Card
              primaryFooterAction={{
                content: 'Add',
                disabled: !formik.dirty || !formik.isValid,
                onAction: () => {
                  formik.submitForm();
                },
                loading: formik.isSubmitting,
              }}
            >
              <Card.Section>
                <Card.Subsection>
                  <Stack vertical>
                    {shopifyProducts.length === 1 ? (
                      <Heading>{shopifyProducts[0].productData.title}</Heading>
                    ) : (
                      <Select
                        requiredIndicator
                        label="Product"
                        options={[
                          {
                            value: '',
                            label: 'Select...',
                            disabled: true,
                            prefix: isShopifyProductsLoading ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Spinner size="small" />
                              </div>
                            ) : (
                              <Icon source={ProductsMinor} />
                            ),
                          },
                          ...shopifyProducts.map((product) => {
                            return {
                              value: product.productId.toString(),
                              label: product.productData.title,
                            };
                          }),
                        ]}
                        value={formik.values.selectedProductId}
                        onChange={(val) => {
                          formik.setFieldValue('selectedProductId', val);
                        }}
                        error={formik.errors.selectedProductId}
                      />
                    )}
                  </Stack>
                </Card.Subsection>
                <Card.Subsection>
                  <Stack vertical>
                    <Select
                      requiredIndicator
                      label="Network"
                      options={[
                        {
                          value: '',
                          label: 'Select...',
                          disabled: true,
                          prefix: <Icon source={ProductsMinor} />,
                        },
                        ...networks.map((network) => {
                          return {
                            value: network.value,
                            label: network.name,
                          };
                        }),
                      ]}
                      value={`${formik.values.network}`}
                      onChange={(val) => {
                        formik.setFieldValue('network', val);
                      }}
                      error={formik.errors.network}
                    />
                    <TextField
                      autoComplete="off"
                      label="Contract address"
                      placeholder="0x123abc..."
                      value={formik.values.contractAddress}
                      onChange={(val) => {
                        formik.setFieldValue('contractAddress', val);
                      }}
                      error={formik.errors.contractAddress}
                      requiredIndicator
                    />
                    {renderSharedContractWarning()}
                    <Stack vertical>
                      <Stack vertical>
                        <RadioButton
                          label="All tokens"
                          helpText="Include holders of any token in a given collection in your allowlist."
                          id="no_filter"
                          name="no_filter"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue('filterType', 'no_filter');
                            }
                          }}
                          checked={formik.values.filterType === 'no_filter'}
                          disabled={
                            isSharedContract(
                              parseInt(formik.values.network),
                              formik.values.contractAddress
                            ) !== ''
                          }
                        />
                        <RadioButton
                          label="Select individual token"
                          helpText="Choose individual token to allowlist for this campaign."
                          id="id"
                          name="id"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue('filterType', 'id');
                            }
                          }}
                          checked={formik.values.filterType === 'id'}
                        />
                        <RadioButton
                          label="Filter by token id range"
                          helpText="Include holders of a given collection in your allowlist only if their token IDs fall in a certain range."
                          id="id_range"
                          name="id_range"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue('filterType', 'id_range');
                            }
                          }}
                          checked={formik.values.filterType === 'id_range'}
                          disabled={
                            isSharedContract(
                              parseInt(formik.values.network),
                              formik.values.contractAddress
                            ) !== ''
                          }
                        />
                        <RadioButton
                          label="Filter by attribute"
                          helpText="Include holders of tokens with a certain metadata attribute onto your allowlist"
                          id="attributes"
                          name="attributes"
                          onChange={(checked) => {
                            if (checked) {
                              formik.setFieldValue('filterType', 'attributes');
                            }
                          }}
                          checked={formik.values.filterType === 'attributes'}
                          disabled={
                            isSharedContract(
                              parseInt(formik.values.network),
                              formik.values.contractAddress
                            ) !== ''
                          }
                        />
                        {formik.errors.filterType && (
                          <ExceptionList
                            items={[
                              {
                                icon: RiskMinor,
                                status: 'critical',
                                description: formik.errors.filterType,
                              },
                            ]}
                          />
                        )}
                      </Stack>
                      {renderFilterTypeDetailSelectors()}
                    </Stack>
                  </Stack>
                </Card.Subsection>
                <Card.Subsection>{renderDiscountInput()}</Card.Subsection>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

import { useQuery } from 'react-query';
import { Campaign } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchAllCampaigns = (shop: string) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: campaigns, isLoading } = useQuery<Campaign[]>({
    queryKey: ['campaigns'],
    queryFn: () => campaignAPI.fetchAllCampaigns(shop),
    enabled: Boolean(campaignAPI.jwtToken),
  });

  return {
    campaigns: campaigns || [],
    isLoading,
  };
};

import { useQuery } from 'react-query';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchCampaignTokenNFTAttributes = (network: number, tokenAddress: string) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: nftAttributes, isLoading } = useQuery<{ traitType: string; value: string }[]>({
    queryKey: ['campaigns', 'nft_attributes', network, tokenAddress],
    queryFn: () => campaignAPI.fetchTokenNFTAttributes(network, tokenAddress),
    enabled: Boolean(network && tokenAddress),
  });

  return {
    nftAttributes: Array.isArray(nftAttributes) ? nftAttributes : [],
    isLoading,
  };
};

import { Card, Layout, Loading, SkeletonBodyText, SkeletonPage } from '@shopify/polaris';

export const ShopifySkeleton: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <>
      <Loading />
      <SkeletonPage title={title}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    </>
  );
};

import { Button } from '@shopify/polaris';
import { useDeleteShopifyProductRuleMutation } from '../../../../hooks/useDeleteShopifyProductRuleMutation';
import { Campaign } from '../../../../utils/interfaces/campaign';
import { useShopifyToastMessage } from '../../hooks/useShopifyToastMessage';

export const DeleteRuleButton: React.FC<{
  campaign: Campaign;
  productId: number;
  ruleId: string;
}> = ({ campaign, productId, ruleId }) => {
  const { setToastMessage } = useShopifyToastMessage();
  const deleteShopifyProductRuleMutation = useDeleteShopifyProductRuleMutation(
    campaign,
    productId,
    ruleId
  );

  return (
    <Button
      plain
      destructive
      loading={deleteShopifyProductRuleMutation.isLoading}
      onClick={async () => {
        try {
          await deleteShopifyProductRuleMutation.mutateAsync();
          setToastMessage('Deleted campaign shopify rule');
        } catch (e) {
          setToastMessage(
            `There was an error deleting this campaign shopify rule. Received: "${
              (e as Error).message || e
            }"`,
            true
          );
        }
      }}
    >
      Delete
    </Button>
  );
};

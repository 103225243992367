import { useMutation, useQueryClient } from 'react-query';
import { useCampaignAPI } from './useCampaignAPI';

export const useRemoveShopifyProductFromCampaignMutation = (campaignId: number) => {
  const queryClient = useQueryClient();
  const { campaignAPI } = useCampaignAPI();

  const mutation = useMutation<void, Error, number[]>(
    async (productIds: number[]) => {
      await Promise.all(
        productIds.map(async (productId) => {
          await campaignAPI.deleteShopifyProduct(campaignId, productId);
        })
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'campaigns',
          campaignId.toString(),
          'shopify_products',
        ]);
      },
    }
  );

  return mutation;
};

import { BaseResource, Product } from '@shopify/app-bridge/actions/ResourcePicker';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { SelectPayload } from '@shopify/app-bridge-react/components/ResourcePicker/ResourcePicker';
import { Button, Card, Modal, Stack, TextContainer, TextStyle, Thumbnail } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAddShopifyProductsToCampaignMutation } from '../../../../hooks/useAddShopifyProductsToCampaignMutation';
import { useCampaignAPI } from '../../../../hooks/useCampaignAPI';
import {
  Campaign,
  CampaignShopifyRule,
  CampaignShopifyRuleRestrictionType,
} from '../../../../utils/interfaces/campaign';
import { useShopifyToastMessage } from '../../hooks/useShopifyToastMessage';
import { ExclusiveCheckboxWithTooltip } from '../ExclusiveProductCheckboxWithTooltip';

interface IProps {
  /** Campaign object associated with the product selection */
  campaign: Campaign;
  /** State of the modal */
  open: boolean;
  /** Modal closed callback */
  onClose?: () => void;
}
/**
 * Renders a modal for adding a single product to a campaign.
 */
export const AddProductToCampaignModal = ({ campaign, open, onClose }: IProps): JSX.Element => {
  const addShopifyProductsToCampaignMutation = useAddShopifyProductsToCampaignMutation(campaign.id);
  const { setToastMessage } = useShopifyToastMessage();
  const { campaignAPI } = useCampaignAPI();
  const queryClient = useQueryClient();

  const [isResourceOpen, setIsResourceOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  // default to exclusive product
  const [exclusiveProduct, setExclusiveProduct] = useState(true);
  const toggleExclusiveProduct = useCallback((exclusive: boolean) => {
    setExclusiveProduct(exclusive);
  }, []);
  const [isMounted, setIsMounted] = useState(false);

  const closeModal = () => {
    if (isMounted) {
      if (isResourceOpen) {
        setIsResourceOpen(false);
      }
      // reset selected
      setSelectedProduct(null);
      toggleExclusiveProduct(true);
    }
    onClose?.();
  };

  const mapProductToId = (product: Product): number => {
    // "gql://shopify/Product/123" -> 123
    return parseInt(product.id.split('/').reverse()[0]);
  };

  /**
   * Adds a non exclusive rule to the item if the item is an exclusive product.
   */
  const tryAddingNonExclusiveRule = async (productId: number): Promise<void> => {
    if (exclusiveProduct) {
      return;
    }
    const campaignId = campaign.id;
    const rule: Partial<CampaignShopifyRule> = {
      restrictionType: CampaignShopifyRuleRestrictionType.UNRESTRICTED,
    };

    try {
      await campaignAPI.addCampaignRule(campaignId, productId, rule);
      await queryClient.invalidateQueries(['campaigns', campaignId.toString(), 'rules']);
    } catch (e) {
      setToastMessage(
        `There was an error adding the product to the campaign as exclusive. Received "${
          (e as Error).message
        }"`,
        true
      );
    }
  };

  const addSelectedProduct = async () => {
    if (selectedProduct) {
      setIsAddingProduct(true);
      const productId = mapProductToId(selectedProduct);
      try {
        await addShopifyProductsToCampaignMutation.mutateAsync([productId]);
        setToastMessage('Added product(s)');
        await tryAddingNonExclusiveRule(productId);
      } catch (e) {
        setToastMessage(
          `There was an error adding product(s). Received "${(e as Error).message || e}"`,
          true
        );
      }
      setIsAddingProduct(false);
    }
    closeModal();
  };

  // resource functions
  // TODO(DEVS-172): grab from shopify API instead
  const onResourceSelection = useCallback(
    ({ selection }: SelectPayload) => {
      const product = selection[0] as Product;
      setSelectedProduct(product);

      if (isMounted) {
        setIsResourceOpen(false);
      }
    },
    [setToastMessage, exclusiveProduct, isMounted]
  );

  const renderProductName = (): JSX.Element => {
    if (selectedProduct) {
      return <p>{selectedProduct.title}</p>;
    }
    return <TextStyle variation="subdued">No product selected</TextStyle>;
  };

  // on mount/unmount
  useEffect(() => {
    function changeMountedState(value: boolean) {
      setIsMounted(value);
    }

    changeMountedState(true);

    return function onUnmount() {
      changeMountedState(false);
    };
  }, []);

  const renderProductPreview = (): JSX.Element => {
    return (
      <Card>
        <Card.Section>
          <Thumbnail
            size="large"
            source={selectedProduct?.images[0]?.originalSrc ?? ''}
            alt={selectedProduct?.title ?? 'placeholder'}
          />
          <TextContainer>{renderProductName()}</TextContainer>
        </Card.Section>
      </Card>
    );
  };

  return (
    <Modal
      open={open}
      title={'Add product to campaign'}
      onClose={closeModal}
      primaryAction={{
        content: 'Add',
        onAction: addSelectedProduct,
        disabled: !selectedProduct,
        loading: isAddingProduct,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: closeModal,
          disabled: isAddingProduct,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          {selectedProduct && renderProductPreview()}
          <Stack.Item>
            <Button
              onClick={() => {
                setIsResourceOpen(true);
              }}
            >
              Select product
            </Button>
          </Stack.Item>
          <Stack.Item>
            <ExclusiveCheckboxWithTooltip
              id={'isRestrictedRule'}
              checked={exclusiveProduct}
              onChange={toggleExclusiveProduct}
              longLabel
              longTooltip
            />
          </Stack.Item>
        </Stack>

        <ResourcePicker
          resourceType="Product"
          actionVerb={ResourcePicker.ActionVerb.Select}
          open={isResourceOpen}
          selectMultiple={false}
          onSelection={onResourceSelection}
          onCancel={() => {
            setIsResourceOpen(false);
          }}
          initialSelectionIds={selectedProduct ? [selectedProduct as BaseResource] : []}
        />
      </Modal.Section>
    </Modal>
  );
};

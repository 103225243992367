import { useMutation, useQueryClient } from 'react-query';
import { Campaign } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

export const useCreateCampaignMutation = () => {
  const { campaignAPI } = useCampaignAPI();
  const queryClient = useQueryClient();

  const mutation = useMutation<Promise<Campaign>, Error, { campaign: Partial<Campaign> }>(
    async ({ campaign }) => {
      return campaignAPI.createCampaign(campaign);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('campaigns');
        queryClient.refetchQueries('campaigns');
      },
    }
  );

  return mutation;
};

import { styled } from '@mui/material/styles';
import { Route, Switch } from 'react-router-dom';
import { ShopifyEmbed } from './components/shopify/pages/ShopifyEmbed';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function App() {
  return (
    <Switch>
      <Route path="/shopify/:appName">
        <ShopifyEmbed />
      </Route>
    </Switch>
  );
}

export default App;

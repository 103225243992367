import { Redirect } from '@shopify/app-bridge/actions';
import { useAppBridge } from '@shopify/app-bridge-react';
import { Icon, IndexTable, Stack, TextStyle, Thumbnail, Tooltip } from '@shopify/polaris';
import { AlertMinor, CircleTickMinor } from '@shopify/polaris-icons';
import { useCallback } from 'react';
import { Campaign, ShopifyProduct } from '../../../../utils/interfaces/campaign';
import { DeleteProductPopoverButton } from './DeleteProductPopoverButton';
import { EditProductPopoverButton } from './EditProductPopoverButton';

interface IProps {
  campaign: Campaign;
  index: number;
  product: ShopifyProduct;
  nonExclusiveRuleId: string;
}

export const ShopifyCampaignProductRow = ({
  campaign,
  index,
  product,
  nonExclusiveRuleId,
}: IProps) => {
  const appBridge = useAppBridge();

  const renderExclusiveIcon = useCallback((isExclusive: boolean): JSX.Element => {
    return (
      <Tooltip
        data-primary-link
        content={
          isExclusive
            ? 'Only specified token holders can access this product.'
            : 'Anyone can purchase this product.'
        }
      >
        <Stack>
          <Icon
            source={isExclusive ? CircleTickMinor : AlertMinor}
            color={isExclusive ? 'success' : 'warning'}
          />
          <TextStyle>{isExclusive ? 'Exclusive' : 'Unrestricted'}</TextStyle>
        </Stack>
      </Tooltip>
    );
  }, []);

  return (
    <IndexTable.Row
      id={
        typeof product.productData.id === 'string'
          ? product.productData.id
          : product.productData.id.toString()
      }
      position={index}
      onNavigation={() => {
        appBridge?.dispatch(
          Redirect.toAdminPath({
            path: `/products/${product.productData.id}`,
          })
        );
      }}
    >
      <IndexTable.Cell>
        <Thumbnail
          size="medium"
          source={product.productData.image?.src ?? ''}
          alt={`Image for product ${product.productData.title}`}
        />
      </IndexTable.Cell>

      <IndexTable.Cell>
        {/* div data-primary-link wrappers to make row clickable */}
        <div data-primary-link>
          <TextStyle>{product.productData.title}</TextStyle>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div data-primary-link>
          <TextStyle>{product.limit}</TextStyle>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div data-primary-link>
          <TextStyle>{product.total}</TextStyle>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div data-primary-link>
          <TextStyle>{product.consumed}</TextStyle>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>{renderExclusiveIcon(!nonExclusiveRuleId)}</IndexTable.Cell>
      <IndexTable.Cell>
        <EditProductPopoverButton
          product={product}
          campaign={campaign}
          nonExclusiveRuleId={nonExclusiveRuleId}
        />
        <DeleteProductPopoverButton product={product} campaign={campaign} />
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};

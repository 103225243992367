import {
  Provider as ShopifyAppBridgeProvider,
  useClientRouting,
  useRoutePropagation,
} from '@shopify/app-bridge-react';
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import '@shopify/polaris/build/esm/styles.css';
import { useCognito } from '../../../hooks/useCognito';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { Link } from '../components/ShopifyLink';
import { ShopifySkeleton } from '../components/ShopifySkeleton';
import { ShopifyStateProvider } from '../hooks/useShopifyState';
import { ShopifyToastProvider } from '../hooks/useShopifyToastMessage';
import { ShopifyAuthenticate } from './ShopifyAuthenticate';
import { ShopifyCampaign } from './ShopifyCampaign';
import { ShopifyCampaignRuleManager } from './ShopifyCampaignRuleManager';
import { ShopifyCampaigns } from './ShopifyCampaigns';
import { ShopifyComplete } from './ShopifyComplete';
import { ShopifyDevelopers } from './ShopifyDevelopers';
import { ShopifyDocumentation } from './ShopifyDocumentation';
import { ShopifyNewCampaign } from './ShopifyNewCampaign';
import { ShopifyWelcome } from './ShopifyWelcome';

const queryClient = new QueryClient();

export const ShopifyEmbed: React.FC = () => {
  const queryParams = useQueryParams();

  return (
    <ShopifyAppBridgeProvider
      config={{
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY!,
        host: queryParams.get('host')!,
      }}
    >
      <ShopifyStateProvider>
        <QueryClientProvider client={queryClient}>
          <ShopifyToastProvider>
            <AppProvider i18n={enTranslations} linkComponent={Link}>
              <Frame>
                <div style={{ padding: '1rem' }}>
                  <EmbeddedPageContent />
                </div>
              </Frame>
            </AppProvider>
          </ShopifyToastProvider>
        </QueryClientProvider>
      </ShopifyStateProvider>
    </ShopifyAppBridgeProvider>
  );
};

const EmbeddedPageContent: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const routeMatchCompletePage = useRouteMatch({ path: '/shopify/:appName/complete' });
  const routeMatchDocumentationPage = useRouteMatch({ path: '/shopify/:appName/documentation' });
  const { cognitoSession } = useCognito();

  useClientRouting(history);
  useRoutePropagation(location);

  /**
   * Show loading if we haven't authenticated yet.
   */
  if (
    !cognitoSession &&
    !Boolean(routeMatchCompletePage) &&
    !Boolean(routeMatchDocumentationPage)
  ) {
    return <ShopifySkeleton title="Loading..."></ShopifySkeleton>;
  }

  return (
    <Switch>
      <Route path="/shopify/:appName/complete" exact>
        <ShopifyComplete />
      </Route>
      <Route path="/shopify/:appName/campaigns" exact>
        <ShopifyCampaigns />
      </Route>
      <Route path="/shopify/:appName/campaigns/new" exact>
        <ShopifyNewCampaign />
      </Route>
      <Route path="/shopify/:appName/campaigns/:campaignId" exact>
        <ShopifyCampaign />
      </Route>
      <Route path="/shopify/:appName/campaigns/:campaignId/rules" exact>
        <ShopifyCampaignRuleManager />
      </Route>
      <Route path="/shopify/:appName/authenticate">
        <ShopifyAuthenticate />
      </Route>
      <Route path="/shopify/:appName/developers" exact>
        <ShopifyDevelopers />
      </Route>
      <Route path="/shopify/:appName/documentation">
        <ShopifyDocumentation />
      </Route>
      <Route>
        <ShopifyWelcome />
      </Route>
    </Switch>
  );
};

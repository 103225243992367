import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppAPI } from '../../../hooks/useAppAPI';
import { useFetchAllApps } from '../../../hooks/useFetchAllApps';
import { ShopifySkeleton } from '../components/ShopifySkeleton';
import { useShopifyState } from '../hooks/useShopifyState';

export const ShopifyAuthenticate = () => {
  const { apps, isLoading: isAppsLoading } = useFetchAllApps();
  const { appAPI } = useAppAPI();
  const { shop } = useShopifyState();
  const { appName } = useParams<{ appName: string }>();
  const history = useHistory();

  // Create new "App" (OAuth) on page load, if there isn't already one.
  // This is the default app so users don't need to think of it
  // The convention is - you can use connect-widget without
  // any props if there is an App with the redirect URI of your
  // website. This logic assumes that the shopify URL is not
  // custom. That's OK for now.
  useEffect(() => {
    async function helper() {
      // Check if there is already an app for this user
      if (!isAppsLoading && (!apps || apps.length == 0)) {
        console.log('Creating default app...', shop);
        const app = await appAPI.createApp(shop, shop, `https://${shop}`, 'token');
        console.log('Created app successfully...', app);
      }

      history.push(`/shopify/${appName}`);
    }
    helper();
  }, [apps, isAppsLoading]);

  return <ShopifySkeleton title="Authenticating..."></ShopifySkeleton>;
};

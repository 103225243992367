import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Campaign } from '../utils/interfaces/campaign';
import { useCampaignAPI } from './useCampaignAPI';

// Note: copied useCreateCampaignMutation
export const useCreateLinkAccountMutation = () => {
  const { campaignAPI } = useCampaignAPI();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    Promise<any>,
    Error,
    {
      shop: string;
      cognitoUserId: string;
    }
  >(
    //@ts-ignore
    async ({ shop, cognitoUserId }) => {
      return campaignAPI.createLinkAccount(shop, cognitoUserId);
    }
    // {
    //   onSuccess: () => {
    //     // console.log('🫡 mutation success!');
    //     continue
    //   },
    // }
  );
  return mutation;
};

import { Card, IndexTable } from '@shopify/polaris';
import { useState } from 'react';
import { useAddShopifyProductsToCampaignMutation } from '../../../../hooks/useAddShopifyProductsToCampaignMutation';
import { CampaignShopifyRuleRestrictionType } from '../../../../utils/interfaces/campaign';
import { IShopifyCardProps } from '../../../../utils/interfaces/shopify';
import { AddProductToCampaignModal } from './AddProductToCampaignModal';
import { ShopifyCampaignProductRow } from './ShopifyCampaignProductRow';

export const ShopifyCampaignShopifyProductsCard = ({
  campaign,
  products,
  productsLoading,
  rules,
}: IShopifyCardProps): JSX.Element => {
  const addShopifyProductsToCampaignMutation = useAddShopifyProductsToCampaignMutation(campaign.id);

  const [isProductSelectionModalOpen, setIsProductSelectionModalOpen] = useState(false);
  const [addProductDisabled, setAddProductDisabled] = useState(false);

  const changeProductSelectionModalVisibility = (open: boolean) => {
    setIsProductSelectionModalOpen(open);
    // if modal is open, button is disabled
    setAddProductDisabled(open);
  };

  return (
    <Card
      title="Products"
      primaryFooterAction={
        products.length === 0
          ? {
              content: 'Add product',
              onAction: () => {
                changeProductSelectionModalVisibility(true);
              },
              loading: addShopifyProductsToCampaignMutation.isLoading,
              disabled: addProductDisabled,
            }
          : undefined
      }
    >
      <Card.Section>
        <IndexTable
          resourceName={{ singular: 'product', plural: 'products' }}
          itemCount={products.length}
          headings={[
            { title: 'Image' },
            { title: 'Item name' },
            { title: 'Redemption limit' },
            { title: 'Total inventory' },
            { title: 'Consumed inventory' },
            { title: 'Type' },
            { title: 'Action', hidden: true },
          ]}
          selectable={false}
          loading={productsLoading || addShopifyProductsToCampaignMutation.isLoading}
          lastColumnSticky
        >
          {products.map((product, index) => {
            // NOTE: ideally, have this flag on the product. for now, filtering.
            // default to exclusive product if no rules
            const nonExclusiveRuleId =
              rules[product.productId]?.find((rule) => {
                return rule.restrictionType === CampaignShopifyRuleRestrictionType.UNRESTRICTED;
              })?.id ?? '';
            const props = {
              campaign,
              index,
              nonExclusiveRuleId,
              product,
            };
            return (
              <ShopifyCampaignProductRow
                key={
                  // Reset anytime product info changes
                  JSON.stringify(product)
                }
                {...props}
              />
            );
          })}
        </IndexTable>
        {products.length === 0 && (
          <AddProductToCampaignModal
            campaign={campaign}
            open={isProductSelectionModalOpen}
            onClose={() => {
              changeProductSelectionModalVisibility(false);
            }}
          />
        )}
      </Card.Section>
    </Card>
  );
};

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, TextField } from '@shopify/polaris';
import { CalendarMajor } from '@shopify/polaris-icons';
import { setSeconds } from 'date-fns';
import { useState } from 'react';

const theme = createTheme();
/**
 * Shopify does not have a DateTimePicker, only a DatePicker, so we are using Material-UI's variation to help us.
 */

export const ShopifyDateTimePicker: React.FC<{
  label: string;
  value: number;
  onChange: (val: number) => void;
  error?: string;
}> = ({ label, value, onChange, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => {
            return (
              <TextField
                autoComplete="off"
                label={label}
                connectedLeft={
                  <div ref={props.inputRef}>
                    <Button icon={CalendarMajor} onClick={() => setIsOpen(true)}></Button>
                  </div>
                }
                value={props.inputProps?.value}
                onChange={(val) => {
                  onChange(Date.parse(val));
                }}
                error={error}
              />
            );
          }}
          minDateTime={new Date()}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onChange={(date) => {
            date && onChange(date.getTime());
          }}
          value={setSeconds(value, 0)}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

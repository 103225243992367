import { TitleBar } from '@shopify/app-bridge-react';
import { Button, Layout, Page } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { useParams } from 'react-router-dom';
import { useFetchCampaign } from '../../../hooks/useFetchCampaign';
import { useFetchCampaignShopifyProducts } from '../../../hooks/useFetchCampaignShopifyProducts';
import { ShopifyCampaignHeaderCard } from '../components/campaign/ShopifyCampaignHeaderCard';
import { ShopifyCampaignProgressCard } from '../components/campaign/ShopifyCampaignProgressCard';
import { SkeletonShopifyCampaign } from '../components/campaign/SkeletonShopifyCampaign';
import { ShopifyCampaignShopifyProductsCard } from '../components/products/ShopifyCampaignShopifyProductsCard';
import { ShopifyCampaignRulesCard } from '../components/rules/ShopifyCampaignRulesCard';

export const ShopifyCampaign = () => {
  const { campaignId, appName } = useParams<{ campaignId: string; appName: string }>();
  const { campaign, isLoading } = useFetchCampaign(campaignId);
  const { shopifyProducts, isLoading: shopifyProductsLoading } = useFetchCampaignShopifyProducts(
    campaign?.id
  );
  const rules = campaign?.rules ?? {};

  if (isLoading || !campaign) {
    return <SkeletonShopifyCampaign />;
  }

  return (
    <>
      <Button icon={ChevronLeftMinor} url={`/shopify/${appName}/campaigns`}></Button>
      <Page title={`${campaign.name} (ID: ${campaign.id})`}>
        <TitleBar
          title={campaignId}
          breadcrumbs={[
            {
              content: 'Product Gates',
              url: `/shopify/${appName}/campaigns`,
            },
          ]}
          primaryAction={{ content: 'New Product Gate', url: `/shopify/${appName}/campaigns/new` }}
        />
        <Layout>
          <Layout.Section>
            <ShopifyCampaignHeaderCard campaign={campaign} />
            <ShopifyCampaignShopifyProductsCard
              campaign={campaign}
              products={shopifyProducts}
              productsLoading={shopifyProductsLoading}
              rules={rules}
            />
            <ShopifyCampaignRulesCard
              campaign={campaign}
              products={shopifyProducts}
              productsLoading={shopifyProductsLoading}
              rules={rules}
            />
            <ShopifyCampaignProgressCard campaign={campaign} />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

import { Checkbox, CheckboxProps, Tooltip } from '@shopify/polaris';
import { QuestionMarkInverseMinor } from '@shopify/polaris-icons';

const kLongTooltip = 'Check if there is unlimited inventory of this product.';

const kShortTooltip = 'Whether there is unlimited inventory.';

const kLongLabel = 'Unlimited inventory for the product';
const kShortLabel = 'Unlimited Inventory';

const renderCheckboxLabel = (longLabel: boolean, longTooltip: boolean): JSX.Element => {
  return (
    <Tooltip content={longTooltip ? kLongTooltip : kShortTooltip}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        {longLabel ? kLongLabel : kShortLabel}{' '}
        <QuestionMarkInverseMinor
          style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
        />
      </span>
    </Tooltip>
  );
};

interface IProps extends Omit<CheckboxProps, 'label'> {
  longLabel?: boolean;
  longTooltip?: boolean;
}

export const ExplicitNoTotalCheckboxWithTooltip = ({
  longLabel = false,
  longTooltip = false,
  ...props
}: IProps) => {
  return <Checkbox label={renderCheckboxLabel(longLabel, longTooltip)} {...props} />;
};

import { Banner, Card, EmptyState, IndexTable, Tag } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import {
  CampaignShopifyProductRules,
  CampaignShopifyRule,
  CampaignShopifyRuleRestrictionType,
  DiscountType,
  ShopifyProduct,
} from '../../../../utils/interfaces/campaign';
import { IRuleRowData, IShopifyCardProps } from '../../../../utils/interfaces/shopify';
import { ShopifyCampaignRuleRow } from './ShopifyCampaignRuleRow';

const buildRulesRows = (
  campaignRules: CampaignShopifyProductRules,
  shopifyProducts: ShopifyProduct[]
) => {
  if (!shopifyProducts.length) {
    return [];
  }

  const rows: IRuleRowData[] = [];
  const shopifyProductsMap = shopifyProducts.reduce(
    (acc: { [key in string | number]: ShopifyProduct }, product) => {
      acc[product.productId] = product;
      return acc;
    },
    {}
  );

  Object.entries(campaignRules).forEach(([productId, rules]) => {
    const product = shopifyProductsMap[productId]!;

    rules.forEach((rule: CampaignShopifyRule) => {
      if (rule.restrictionType === CampaignShopifyRuleRestrictionType.UNRESTRICTED) {
        return;
      }
      let discountType = DiscountType.Percent;
      // only take amount if percent = 0 and amount is not 0
      if (rule.discountAmount && rule.discountPercent === 0) {
        discountType = DiscountType.Amount;
      }

      const rowData: Partial<IRuleRowData> = {
        discountType,
        discountValue:
          discountType === DiscountType.Percent ? rule.discountPercent : rule.discountAmount,
        id: rule.id,
        product,
        restrictionCriteria: 'none',
        restrictionLabel: 'none',
      };

      switch (rule.restrictionType) {
        case CampaignShopifyRuleRestrictionType.TOKEN:
          Object.assign(rowData, {
            restrictionLabel: 'Contract',
            network: rule.restriction.network,
            contract: rule.restriction.tokenAddress,
          });
          break;
        case CampaignShopifyRuleRestrictionType.INDIVIDUAL_TOKEN:
          Object.assign(rowData, {
            restrictionLabel: 'Individual Token',
            network: rule.restriction.network,
            contract: rule.restriction.tokenAddress,
            restrictionCriteria: `Token #${rule.restriction.tokenId}`,
          });
          break;
        case CampaignShopifyRuleRestrictionType.TOKEN_ATTRIBUTE:
          Object.assign(rowData, {
            restrictionLabel: 'Attribute(s)',
            restrictionCriteria: (
              <div style={{ display: 'flex', flexFlow: 'column', gap: '0.25rem' }}>
                {rule.restriction.attributeEntries.map(
                  ({ traitType, value }: { traitType: string; value: string }) => (
                    <Tag key={`${traitType}-${value}`}>
                      {traitType} - {value}
                    </Tag>
                  )
                )}
              </div>
            ),
            network: rule.restriction.network,
            contract: rule.restriction.tokenAddress,
          });
          break;
        case CampaignShopifyRuleRestrictionType.TOKEN_RANGE:
          Object.assign(rowData, {
            restrictionLabel: 'Token ID Range',
            restrictionCriteria: `Token IDs: ${rule.restriction.minTokenId!} - ${rule.restriction
              .maxTokenId!}`,
            network: rule.restriction.network,
            contract: rule.restriction.tokenAddress,
          });
          break;
      }
      rows.push(rowData as IRuleRowData);
    });
  });

  return rows;
};

export const ShopifyCampaignRulesCard = ({
  campaign,
  products,
  productsLoading,
  rules,
}: IShopifyCardProps): JSX.Element => {
  const { appName } = useParams<{ campaignId: string; appName: string }>();

  const rulesRows = buildRulesRows(rules, products);

  return (
    <Card
      title="Rules"
      primaryFooterAction={{
        content: 'Add rule',
        url: `/shopify/${appName}/campaigns/${campaign.id}/rules`,
        disabled: !products.length,
      }}
    >
      <Card.Section>
        <IndexTable
          loading={productsLoading}
          emptyState={
            !products.length ? (
              <Banner status="critical" title="Add a product before adding rules"></Banner>
            ) : (
              <EmptyState
                heading="Add rules to get started"
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              ></EmptyState>
            )
          }
          resourceName={{ plural: 'rules', singular: 'rule' }}
          itemCount={rulesRows.length}
          selectable={false}
          headings={[
            { title: 'Product' },
            { title: 'Network' },
            { title: 'Contract' },
            { title: 'Restriction' },
            { title: 'Restriction Info' },
            { title: 'Discount' },
            { title: 'Actions', hidden: true },
          ]}
        >
          {rulesRows.map((rule, i) => {
            const props = {
              index: i,
              campaign,
              rule,
            };
            return <ShopifyCampaignRuleRow key={rule.id} {...props} />;
          })}
        </IndexTable>
      </Card.Section>
    </Card>
  );
};

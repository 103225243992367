import { Button, FormLayout, Popover, Tooltip } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useRemoveShopifyProductFromCampaignMutation } from '../../../../hooks/useRemoveShopifyProductFromCampaignMutation';
import { Campaign, ShopifyProduct } from '../../../../utils/interfaces/campaign';
import { useShopifyToastMessage } from '../../hooks/useShopifyToastMessage';
import { EditProductFormValues } from './ProductTypes';

export const DeleteProductPopoverButton: React.FC<{
  product: ShopifyProduct;
  campaign: Campaign;
}> = ({ campaign, product }) => {
  const [isDeleteProductOpen, setIsDeleteProductOpen] = useState(false);
  const removeProductsFromCampaign = useRemoveShopifyProductFromCampaignMutation(campaign.id);
  const queryClient = useQueryClient();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    function changeMountedState(value: boolean) {
      setIsMounted(value);
    }

    changeMountedState(true);

    return function onUnmount() {
      changeMountedState(false);
    };
  }, []);

  const { setToastMessage } = useShopifyToastMessage();
  // TODO: remove initial values (since delete = just a button)
  const formik = useFormik<EditProductFormValues>({
    validateOnMount: false,
    initialValues: {
      // TODO: Remove once migration complete
      redemptionLimit: product.limit || 1,
      totalInventory: product.total || 0,
      exclusive: false, // unused
      explicitNoTotal: false, // unused,
    },
    validate: (values) => {
      const errors: FormikErrors<EditProductFormValues> = {};
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await removeProductsFromCampaign.mutateAsync([product.productId]);
        await queryClient.invalidateQueries(['campaigns', campaign.id.toString(), 'rules']);
        if (isMounted) {
          setIsDeleteProductOpen(false);
          setToastMessage('Deleted product');
        }
      } catch (e) {
        if (isMounted) {
          setToastMessage(
            `There was an error deleting product. Received: ${(e as Error).message || e}`,
            true
          );
        }
      }

      if (isMounted) {
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover
        preferredPosition="above"
        sectioned
        activator={
          <Tooltip content="Delete">
            <Button
              outline
              icon={DeleteMinor}
              onClick={() => {
                setIsDeleteProductOpen(true);
              }}
            ></Button>
          </Tooltip>
        }
        onClose={() => setIsDeleteProductOpen(false)}
        active={isDeleteProductOpen}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormLayout>
            <Button destructive submit loading={formik.isSubmitting}>
              Confirm Delete
            </Button>
          </FormLayout>
        </form>
      </Popover>
    </div>
  );
};

import { useAppBridge, useNavigate } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useCognito } from '../../../hooks/useCognito';
import { useQueryParams } from '../../../hooks/useQueryParams';

const ShopifyStateContext = createContext<{
  shop: string;
  network: string;
  sessionToken: string;
}>({
  shop: '',
  network: '',
  sessionToken: '',
});

let interval: NodeJS.Timeout;
const authQueryParams = window.location.search;

export const ShopifyStateProvider: React.FC = ({ children }) => {
  const [shop, setShop] = useState('');
  const [network, setNetwork] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const app = useAppBridge();
  const query = useQueryParams();
  const routeMatchCompletePage = useRouteMatch({ path: '/shopify/:appName/complete' });
  const routeMatchDocumentationPage = useRouteMatch({ path: '/shopify/:appName/documentation' });
  const navigate = useNavigate();

  const { appName } = useParams<{ appName: string }>();

  const { userPool, setCognitoSession } = useCognito();

  const shopInQuery = query.get('shop');
  const networkInQuery = query.get('network');

  const REDEEM_SERVER = `https://${network || ''}${process.env.REACT_APP_REDEEM_SERVER_HOST}`;

  const triggerOAuth = (forceParent = false) => {
    const oauthLocation = `${REDEEM_SERVER}/shopify/${appName}/authenticate${authQueryParams}`;
    console.log(`Triggering OAuth:`, oauthLocation);
    if (forceParent && window.top && window.top !== window.self) {
      navigate(oauthLocation);
    } else {
      window.location.href = oauthLocation;
    }
  };

  useEffect(() => {
    shopInQuery && setShop(shopInQuery);
    networkInQuery && setNetwork(networkInQuery);
  }, [shopInQuery, networkInQuery]);

  useEffect(() => {
    /** Go through oauth flow if not in iframe*/
    const isEmbed = window.top !== window.self;

    if (!isEmbed && !Boolean(routeMatchCompletePage) && !Boolean(routeMatchDocumentationPage)) {
      triggerOAuth();
    }

    const setInitialSessionToken = async () => {
      const sess = await getSessionToken(app);
      setSessionToken(sess);
    };
    setInitialSessionToken();
  }, []);

  useEffect(() => {
    // update session token every 60 seconds
    clearInterval(interval);
    interval = setInterval(async () => {
      setSessionToken(await getSessionToken(app));
    }, 60000);
  }, [app]);

  useEffect(() => {
    if (shop && sessionToken) {
      // Check access scopes
      fetch(`${REDEEM_SERVER}/shopify/${appName}/check/scopes?shop=${shop}`).then((response) => {
        if (response.status === 404) {
          console.log('Access scopes changed');
          triggerOAuth(true);
        } else if (response.status === 200) {
          const authenticationData = {
            Username: shop.replace('.myshopify.com', '@myshopify.com'),
            Pool: userPool,
          };
          const authenticationDetails = new AuthenticationDetails(authenticationData);
          const cognitoUser = new CognitoUser(authenticationData);
          cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
          cognitoUser.initiateAuth(authenticationDetails, {
            onSuccess: function (result) {
              setCognitoSession(result);
            },
            onFailure: function (err) {
              /** If we're not logged in, go through oauth flow */
              triggerOAuth();
            },
            customChallenge: function () {
              cognitoUser.sendCustomChallengeAnswer(sessionToken, this, {
                stage: network || 'mainnet',
                appName,
              });
            },
          });
        } else {
          console.log(`Failure to check access scopes`);
        }
      });
    }
  }, [shop, sessionToken]);

  return (
    <ShopifyStateContext.Provider
      value={{
        shop,
        network,
        sessionToken,
      }}
    >
      {children}
    </ShopifyStateContext.Provider>
  );
};

export const useShopifyState = () => {
  return useContext(ShopifyStateContext);
};

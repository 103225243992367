import { TitleBar } from '@shopify/app-bridge-react';
import {
  Banner,
  Button,
  Checkbox,
  FormLayout,
  Layout,
  Page,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import {
  ChevronLeftMinor,
  CirclePlusOutlineMinor,
  QuestionMarkInverseMinor,
} from '@shopify/polaris-icons';
import { FormikErrors, useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateCampaignMutation } from '../../../hooks/useCreateCampaignMutation';
import { Campaign, CampaignAction } from '../../../utils/interfaces/campaign';
import { ShopifyDateTimePicker } from '../components/ShopifyDateTimePicker';
import { useShopifyToastMessage } from '../hooks/useShopifyToastMessage';

type FormValues = {
  campaignName: string;
  startTime: number;
  endTime: number;
  snapshotTime: number;
  isSnapshot: boolean;
  allowUnlimitedRedemptions: boolean;
  hasRedemptionsPerAddress: boolean;
  redemptionsPerAddress: number;
  explicitNoEndDate: boolean;
};

const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

export const ShopifyNewCampaign = () => {
  const { appName } = useParams<{ appName: string }>();
  const history = useHistory();
  const { setToastMessage } = useShopifyToastMessage();

  const createCampaignMutation = useCreateCampaignMutation();

  const formik = useFormik<FormValues>({
    initialValues: {
      campaignName: '',
      snapshotTime: Date.now() + ONE_HOUR_IN_MILLISECONDS * 23,
      startTime: Date.now() + ONE_HOUR_IN_MILLISECONDS * 24,
      endTime: Date.now() + ONE_HOUR_IN_MILLISECONDS * 48,
      isSnapshot: false,
      allowUnlimitedRedemptions: false,
      hasRedemptionsPerAddress: false,
      redemptionsPerAddress: 1,
      explicitNoEndDate: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.campaignName) {
        errors.campaignName = 'Please enter a name for your campaign';
      }

      if (!values.explicitNoEndDate) {
        if (!values.endTime) {
          errors.endTime = 'Please enter an end time';
        } else if (values.endTime <= values.startTime!) {
          errors.endTime = 'End Date cannot be before Start Date';
        }
      }

      if (values.isSnapshot) {
        if (!values.snapshotTime) {
          errors.snapshotTime = 'Please select a snapshot date or uncheck the snapshot option.';
        } else if (values.startTime - ONE_HOUR_IN_MILLISECONDS < values.snapshotTime) {
          errors.snapshotTime = 'Snapshot time must be at least 60 minutes before start date.';
        } else if (values.snapshotTime < Date.now()) {
          errors.snapshotTime = 'Snapshot cannot be in the past';
        }
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const campaignToSubmit: Partial<Campaign & { explicitNoEndDate?: boolean }> = {
        name: values.campaignName,
        snapshot: values.isSnapshot,
        redemptionsPerToken: values.allowUnlimitedRedemptions ? null : 1,
        redemptionsPerAddress: values.hasRedemptionsPerAddress
          ? values.redemptionsPerAddress
          : null,
        startDate: values.startTime ? values.startTime : Date.now(),
        endDate: values.explicitNoEndDate ? null : values.endTime,
        action: CampaignAction.SHOPIFY_URL,
      };
      if (values.isSnapshot && values.snapshotTime) {
        campaignToSubmit['snapshotDate'] = values.snapshotTime;
      }
      if (values.explicitNoEndDate) {
        campaignToSubmit['explicitNoEndDate'] = values.explicitNoEndDate;
      }

      try {
        const campaign = await createCampaignMutation.mutateAsync({
          campaign: campaignToSubmit,
        });
        history.push(`/shopify/${appName}/campaigns/${campaign.id}`);
        setToastMessage('Campaign created');
      } catch (e) {
        setToastMessage(`There was an error creating the campaign. Received "${e}"`, true);
        console.error(e);
      }

      setSubmitting(false);
    },
  });

  return (
    <>
      <Button size="slim" icon={ChevronLeftMinor} url={`/shopify/${appName}/campaigns`}></Button>

      <Page title="New Product Gate">
        <TitleBar
          title="new"
          breadcrumbs={[
            {
              content: 'Product Gates',
              url: `/shopify/${appName}/campaigns`,
            },
          ]}
        />
        <Layout>
          <Layout.Section>
            <form onSubmit={formik.handleSubmit}>
              <FormLayout>
                <TextField
                  label="Gate Name"
                  autoFocus
                  autoComplete="off"
                  error={formik.errors.campaignName}
                  value={formik.values.campaignName}
                  onChange={(value) => {
                    formik.setFieldValue('campaignName', value);
                  }}
                  requiredIndicator
                />
                <ShopifyDateTimePicker
                  label="Start Date"
                  error={formik.errors.startTime}
                  value={formik.values.startTime}
                  onChange={(val) => formik.setFieldValue('startTime', val)}
                />
                {!formik.values.explicitNoEndDate && (
                  <ShopifyDateTimePicker
                    label="End Date"
                    error={formik.errors.endTime}
                    value={formik.values.endTime}
                    onChange={(val) => formik.setFieldValue('endTime', val)}
                  />
                )}
                <Checkbox
                  label="No End Date"
                  id="explicitNoEndDate"
                  checked={Boolean(formik.values.explicitNoEndDate)}
                  onChange={(val) => formik.setFieldValue('explicitNoEndDate', val)}
                  error={formik.errors.explicitNoEndDate}
                />
                <Checkbox
                  label={
                    <Tooltip content="Allow a token holder to redeem multiple times. If not selected, a token holder can only redeem once.">
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        Allow Unlimited Redemptions{' '}
                        <QuestionMarkInverseMinor
                          style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                        />
                      </span>
                    </Tooltip>
                  }
                  id="allowUnlimitedRedemptions"
                  checked={Boolean(formik.values.allowUnlimitedRedemptions)}
                  onChange={(val) =>
                    formik.setFieldValue('allowUnlimitedRedemptions', Boolean(val))
                  }
                  error={formik.errors.allowUnlimitedRedemptions}
                />
                <Checkbox
                  label={
                    <Tooltip content="Enforce a max number of redemptions per wallet">
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        Enforce Max Redemptions Per Wallet{' '}
                        <QuestionMarkInverseMinor
                          style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                        />
                      </span>
                    </Tooltip>
                  }
                  id="hasRedemptionsPerAddress"
                  checked={Boolean(formik.values.hasRedemptionsPerAddress)}
                  onChange={(val) => formik.setFieldValue('hasRedemptionsPerAddress', Boolean(val))}
                  error={formik.errors.hasRedemptionsPerAddress}
                />
                {formik.values.hasRedemptionsPerAddress && (
                  <>
                    <TextField
                      id="redemptionsPerAddress"
                      autoComplete="off"
                      label={
                        <Tooltip content="Maximum number redemptions per wallet">
                          <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            Max Redemptions Per Wallet{' (Optional) '}
                            <QuestionMarkInverseMinor
                              style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                            />
                          </span>
                        </Tooltip>
                      }
                      value={formik.values.redemptionsPerAddress.toString()}
                      onChange={(val) =>
                        formik.setFieldValue('redemptionsPerAddress', val ? parseInt(val) : null)
                      }
                      error={formik.errors.redemptionsPerAddress}
                      type="number"
                      min={1}
                      requiredIndicator
                    />
                  </>
                )}
                <Checkbox
                  label={
                    <Tooltip content="Allows only wallets holding tokens at the specified time to be eligible for the campaign. A snapshot date/time is required for campaigns using ERC-1155 tokens or if wallet-based redemption is desired.  Leaving this blank for ERC-721 tokens will allow campaign eligibility to travel with the NFT.">
                      <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                        Snapshot{' '}
                        <QuestionMarkInverseMinor
                          style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
                        />
                      </span>
                    </Tooltip>
                  }
                  id="isSnapshot"
                  checked={Boolean(formik.values.isSnapshot)}
                  onChange={(val) => formik.setFieldValue('isSnapshot', Boolean(val))}
                  error={formik.errors.isSnapshot}
                />

                {formik.values.isSnapshot && (
                  <>
                    <ShopifyDateTimePicker
                      label="Snapshot Date"
                      error={formik.errors.snapshotTime}
                      value={formik.values.snapshotTime}
                      onChange={(val) => formik.setFieldValue('snapshotTime', val)}
                    />
                    <div style={{ marginTop: '1rem' }}>
                      <Banner
                        title="Snapshot campaigns require additional configuration"
                        status="warning"
                      >
                        <p>
                          Make sure to configure token gating rules prior to the snapshot date (can
                          be done on the next screen), or the snapshot will not capture the correct
                          addresses.
                        </p>
                      </Banner>
                    </div>
                  </>
                )}
                <Button primary submit loading={formik.isSubmitting} icon={CirclePlusOutlineMinor}>
                  {formik.isSubmitting ? 'Creating' : 'Create'}
                </Button>
              </FormLayout>
            </form>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

import { useQuery } from 'react-query';
import { useCampaignAPI } from './useCampaignAPI';

export const useFetchLinkedAccountsEmails = (shop?: string) => {
  const { campaignAPI } = useCampaignAPI();

  const { data: emails, isLoading } = useQuery<string[]>({
    queryKey: ['emails'],
    queryFn: () => campaignAPI.fetchLinkedAccountsEmails(shop!),
    enabled: Boolean(shop && campaignAPI.jwtToken),
  });

  return {
    emails: emails || [],
    isLoading,
  };
};

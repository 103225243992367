import { BaseAPI, PostOptions } from './BaseAPI';

export interface AppDetails {
  app?: string;
  appName: string;
  description: string;
  grantType: string;
  redirectUri: string;
  walletConnectProjectId?: string;
}

export const MYSHOPIFY_WALLET_CONNECT_PROJECT_ID = '04c8c25b523a5492a13c42137557c7f5';

export class AppAPI extends BaseAPI {
  network: string | null;
  baseUrl = 'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod';

  constructor(network: string | null, jwtToken?: string) {
    super(jwtToken);
    this.network = network;
  }

  pathToUrl(path: string) {
    if (path.includes('?')) return `${this.baseUrl}${path}&stage=${this.network || 'mainnet'}`;
    return `${this.baseUrl}${path}?stage=${this.network || 'mainnet'}`;
  }

  fetch(path: string, options: RequestInit = {}) {
    return super.fetch(this.pathToUrl(path), options);
  }

  put(path: string, options: PostOptions = {}) {
    return super.put(this.pathToUrl(path), options);
  }

  post(path: string, options: PostOptions = {}) {
    return super.post(this.pathToUrl(path), options);
  }

  delete(path: string, options: RequestInit = {}) {
    return super.delete(this.pathToUrl(path), options);
  }

  async getApps() {
    return await this.fetch('/app/all');
  }

  async createApp(appName: string, description: string, redirectUri: string, grantType: string) {
    return await this.post('/app/new', {
      body: {
        appName,
        description,
        redirectUri,
        grantType,
        // Default wallet connect project id
        walletConnectProjectId: redirectUri.endsWith('myshopify.com')
          ? MYSHOPIFY_WALLET_CONNECT_PROJECT_ID
          : undefined,
      },
    });
  }

  /**
   * Update the given app
   *
   * @param appDetails              - The original app details
   * @param redirectUri             - The new redirectURI
   * @param walletConnectProjectId  - The new walletConnectProjectId
   * @returns
   */
  async updateApp(appDetails: AppDetails, newDetails: Partial<AppDetails>) {
    return await this.put(`/app/${appDetails.app}`, {
      body: {
        appName: newDetails.appName ?? appDetails.appName,
        description: newDetails.description ?? appDetails.description,
        redirectUri: newDetails.redirectUri ?? appDetails.redirectUri,
        walletConnectProjectId:
          newDetails.walletConnectProjectId === undefined
            ? appDetails.walletConnectProjectId
            : newDetails.walletConnectProjectId,
      },
    });
  }
}

import { useMutation, useQueryClient } from 'react-query';
import { useShopifyState } from '../components/shopify/hooks/useShopifyState';
import { useCampaignAPI } from './useCampaignAPI';

export const useAddShopifyProductsToCampaignMutation = (campaignId: number) => {
  const queryClient = useQueryClient();
  const { campaignAPI } = useCampaignAPI();
  const { shop } = useShopifyState();

  const mutation = useMutation<void, Error, number[]>(
    async (productIds: number[]) => {
      await Promise.all(
        productIds.map(async (productId) => {
          await campaignAPI.addShopifyProduct(campaignId, {
            shop,
            productId: productId,
          });
        })
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'campaigns',
          campaignId.toString(),
          'shopify_products',
        ]);
      },
    }
  );

  return mutation;
};

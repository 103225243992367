import { TitleBar } from '@shopify/app-bridge-react';
import { Layout, Page, TextStyle } from '@shopify/polaris';
import { Link } from '../components/ShopifyLink';

export const ShopifyDocumentation = () => {
  return (
    <Page titleHidden={false} title="Documentation">
      <TitleBar title="Documentation" />
      <Layout>
        <Layout.Section>
          <TextStyle variation="strong">
            Visit the official{' '}
            <Link
              url="https://docs.manifold.xyz/v/manifold-for-developers/shopify-merch-bridge/overview"
              external
            >
              Shopify Merch Bridge Documentation
            </Link>{' '}
            for tutorials and installation resrouces.
          </TextStyle>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

import { useEffect, useState } from 'react';
import { AppAPI } from '../utils/AppAPI';
import { useCognito } from './useCognito';
import { useNetwork } from './useNetwork';

export const useAppAPI = () => {
  const { cognitoSession } = useCognito();
  const network = useNetwork();
  const jwtToken = cognitoSession?.getIdToken().getJwtToken();
  const [appAPI, setAppAPI] = useState<AppAPI>(new AppAPI(network, jwtToken));

  useEffect(() => {
    setAppAPI(new AppAPI(network, jwtToken));
  }, [network, jwtToken]);

  return { appAPI };
};

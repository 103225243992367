import { useQuery } from 'react-query';
import { useAppAPI } from './useAppAPI';

export const useFetchAllApps = () => {
  const { appAPI } = useAppAPI();

  const { data: apps, isLoading } = useQuery<any[]>({
    queryKey: ['apps'],
    queryFn: () => appAPI.getApps(),
    enabled: Boolean(appAPI.jwtToken),
  });

  return {
    apps: apps || [],
    isLoading,
  };
};

import { TitleBar } from '@shopify/app-bridge-react';
import {
  Banner,
  Button,
  Card,
  FormLayout,
  IndexTable,
  Layout,
  Page,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import { ChevronLeftMinor, CirclePlusOutlineMinor } from '@shopify/polaris-icons';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCognito } from '../../../hooks/useCognito';
import { useCreateLinkAccountMutation } from '../../../hooks/useCreateLinkAccountMutation';
import { useFetchLinkedAccountsEmails } from '../../../hooks/useFetchLinkedAccountsEmails';
import { useShopifyState } from '../hooks/useShopifyState';
import { useShopifyToastMessage } from '../hooks/useShopifyToastMessage';

type FormValues = {
  email: string;
  password: string;
};

export const ShopifyDevelopers = () => {
  const { appName } = useParams<{ appName: string }>();
  const { setToastMessage } = useShopifyToastMessage();
  const { userPool } = useCognito();
  const [myCognitoUser, setMyCognitoUser] = useState<any>();
  const [myCognitoSession, setMyCognitoSession] = useState<any>();
  const { shop } = useShopifyState();
  const { emails } = useFetchLinkedAccountsEmails(shop);

  const createLinkAccountMutation = useCreateLinkAccountMutation();

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.email) {
        errors.email = 'Please enter a email';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Please enter a password';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      // "Sign in" with the dev-portal-frontend credentials
      const email = values.email;
      const password = values.password;

      const authenticationData = {
        Username: email,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);

      const dataUserName = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(dataUserName);

      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function (result) {
          setMyCognitoUser(cognitoUser);
          setMyCognitoSession(result);
          const devPortalCognitoUserId = result.getIdToken().payload.sub;

          // Hit the endpoint to link accounts
          try {
            await createLinkAccountMutation.mutateAsync({
              shop: shop,
              cognitoUserId: devPortalCognitoUserId,
            });
            setToastMessage('Account Successfully Linked!');
            resetForm();
            window.open('https://developer.manifoldxyz.dev/');
          } catch (e) {
            setToastMessage(`Something went wrong: "${e}"`, true);
            throw new Error();
          }
        },
        onFailure: function (err) {
          if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
            console.log('Error linking accounts: ', err);
            setToastMessage(`Could not link that account "${err}"`, true);
          }
          // User authentication was not successful
        },
      });
      setSubmitting(false);
    },
  });

  return (
    <>
      <Button size="slim" icon={ChevronLeftMinor} url={`/shopify/${appName}/campaigns`}></Button>

      <Page title="Developer Portal Login">
        <TitleBar title="Developers" />
        <Layout>
          <Layout.Section>
            <Banner
              title="Want to Create Custom Merch Bridge Installations on a standalone site via our Campaign Widget?"
              secondaryAction={{
                content: 'https://developer.manifoldxyz.dev/',
                url: 'https://developer.manifoldxyz.dev/',
              }}
              status="info"
            >
              <p>
                Link a Manifold Developer Portal Account using the form below to manage your
                products in the Developer Portal. If you do not have an acount you can create one
                here:
              </p>
            </Banner>
          </Layout.Section>
          <Layout.Section>
            <form onSubmit={formik.handleSubmit}>
              <FormLayout>
                <TextField
                  label="Email"
                  autoFocus
                  autoComplete="off"
                  error={formik.errors.email}
                  value={formik.values.email}
                  onChange={(value) => {
                    formik.setFieldValue('email', value);
                  }}
                  requiredIndicator
                />
                <TextField
                  label="Password"
                  type="password"
                  autoFocus
                  autoComplete="off"
                  error={formik.errors.password}
                  value={formik.values.password}
                  onChange={(value) => {
                    formik.setFieldValue('password', value);
                  }}
                  requiredIndicator
                />
                <Button primary submit loading={formik.isSubmitting} icon={CirclePlusOutlineMinor}>
                  {formik.isSubmitting ? 'Linking Account' : 'Link Account'}
                </Button>
              </FormLayout>
            </form>
          </Layout.Section>
          <Layout.Section></Layout.Section>
          <Layout.Section>
            <Card>
              <IndexTable
                resourceName={{ plural: 'linkedAccounts', singular: 'linkedAccount' }}
                itemCount={emails.length}
                selectable={false}
                headings={[
                  { title: 'Email' },
                  { title: 'Status' },
                  { title: 'Actions', hidden: false },
                ]}
                sort
              >
                {emails.map((email, index) => {
                  return (
                    <IndexTable.Row
                      onNavigation={() => {
                        // history.push(`/shopify/${appName}/developers/${account.id}`);
                      }}
                      id={email}
                      key={email}
                      position={index}
                    >
                      <IndexTable.Cell>
                        <div data-primary-link>
                          <TextStyle variation="strong">{email}</TextStyle>
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div data-primary-link>
                          <TextStyle>{'linked'}</TextStyle>
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div
                          style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Button
                            plain
                            primary
                            onClick={() =>
                              window.open('https://developer.manifoldxyz.dev/', '_blank')
                            }
                          >
                            View
                          </Button>
                          {/* <Button
                            plain
                            destructive
                            onClick={() => {
                              setCampaignToDelete(campaign);
                            }}
                          >
                            Delete
                          </Button> */}
                        </div>
                      </IndexTable.Cell>
                    </IndexTable.Row>
                  );
                })}
              </IndexTable>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

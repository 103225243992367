import { Badge, BadgeProps } from '@shopify/polaris';
import { Campaign, CampaignStatus } from '../../../utils/interfaces/campaign';

export const ShopifyCampaignStatusBadge: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
  return <Badge status={getBadgeStatusFromCampaignStatus(campaign)}>{campaign.statusString}</Badge>;
};

const getBadgeStatusFromCampaignStatus = (campaign: Campaign): BadgeProps['status'] => {
  const campaignStatusToBadgeStatus = {
    [CampaignStatus.ACTIVE]: 'success',
    [CampaignStatus.COMPLETED]: 'info',
    [CampaignStatus.SCHEDULED]: 'new',
  } as const;

  if (!campaign.active) {
    return 'info';
  }

  return campaignStatusToBadgeStatus[campaign.status] || 'info';
};

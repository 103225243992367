import { Checkbox, CheckboxProps, Tooltip } from '@shopify/polaris';
import { QuestionMarkInverseMinor } from '@shopify/polaris-icons';

const kLongTooltip =
  'Check to gate the access of this product to token holders only. ' +
  'Leave blank if you want anyone to be able to purchase the product at the regular price, ' +
  'while offering token holders a discount (configured in the "Rules" section).';

const kShortTooltip =
  'Whether the access is gated to token holders (exclusive) or not (available to the public).';

const kLongLabel = 'This is an exclusive product';
const kShortLabel = 'Exclusive';

const renderCheckboxLabel = (longLabel: boolean, longTooltip: boolean): JSX.Element => {
  return (
    <Tooltip content={longTooltip ? kLongTooltip : kShortTooltip}>
      <span style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        {longLabel ? kLongLabel : kShortLabel}{' '}
        <QuestionMarkInverseMinor
          style={{ fill: 'var(--p-text)', height: '1rem', width: '1rem' }}
        />
      </span>
    </Tooltip>
  );
};

interface IProps extends Omit<CheckboxProps, 'label'> {
  longLabel?: boolean;
  longTooltip?: boolean;
}

export const ExclusiveCheckboxWithTooltip = ({
  longLabel = false,
  longTooltip = false,
  ...props
}: IProps) => {
  return <Checkbox label={renderCheckboxLabel(longLabel, longTooltip)} {...props} />;
};
